import styled from 'styled-components'

interface IContainerProps {
    enabled: boolean
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: 100%;
  display: ${({enabled}) => enabled ? 'flex' : 'none'};
  place-content: center;
  place-items: center;
  background-color: var(--black-rgba);
  position: fixed;
  z-index: 99;
  transition: all .3s;
  
  img {
    width: 50px;
  }
`
