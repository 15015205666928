import React, { useState } from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { InputForm } from '../../components/Form/InputForm'
import { Link, Navigate } from 'react-router-dom'
import { Button } from '../../components/Form/Button'
import { useAuth } from '../../hooks/auth'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '../../components/Form'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import axios from 'axios'
import { api } from '../../services/api'

const { REACT_APP_TOKEN_REQUEST } = process.env

export function PasswordEmail() {
  const { loggued } = useAuth()
  const [loading, setLoading] = useState(false)

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('O e-mail é obrigatório.')
      .email('E-mail inválido')
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  async function handleSendPassword(form: any) {
    try {
      setLoading(true)
      const { email } = form
      const { data } = await api.post(
        `/password/email`,
        {
          email
        },
        {
          headers: {
            Authorization: `${REACT_APP_TOKEN_REQUEST}`
          }
        }
      )
      ToastNotification({
        text: data.message,
        typeNotify: 'success',
        autoClose: 10000
      })
      await reset()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (e instanceof Yup.ValidationError) {
        ToastNotification({ text: e.message, typeNotify: 'warning' })
      }
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({ text: data.message, typeNotify: 'warning' })
            }
          }
        }
      }
    }
  }

  if (loggued) {
    return <Navigate to={'/'} replace />
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Recuperação de conta Danki Club</title>
      </Helmet>
      <Background>
        <Content>
          <Form
            title={'Alteração de senha'}
            handleSubmit={handleSubmit((data) => handleSendPassword(data))}
          >
            <div>
              <label htmlFor="email">E-mail</label>
              <InputForm
                control={control}
                name={'email'}
                type="email"
                id={'email'}
                placeholder={'E-mail'}
                error={errors.email && errors.email.message}
              />
            </div>
            <p>
              <Link to="/sign" title={'Entrar com seus dados de acesso'}>
                Entrar
              </Link>
              <a
                href="https://dankiclub.com"
                rel={"noreferrer"}
                target={'_blank'}
                title={'Escolha um plano e Cadastre-se no Danki Club'}
              >
                Cadastre-se
              </a>
            </p>
            <div>
              <Button title={'Enviar'} disabled={loading} type={'submit'} />
            </div>
          </Form>
        </Content>
      </Background>
    </>
  )
}
