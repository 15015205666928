import React, { InputHTMLAttributes, useState } from 'react'
import { FormInput, ToggleShowPassButton, InputContainer } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  active?: boolean
  securityEntry: boolean
  type: string
}

export function Input({ securityEntry, type, active = false, ...rest }: Props) {
  const [passwordHidden, setPasswordHidden] = useState(true)

  return (
    <InputContainer active={active}>
      <FormInput
        active={active}
        type={securityEntry && !passwordHidden ? 'text' : type}
        {...rest}
      />
      {securityEntry && (
        <ToggleShowPassButton
          onClick={() => setPasswordHidden(!passwordHidden)}
        >
          <FontAwesomeIcon icon={passwordHidden ? faEyeSlash : faEye} />
        </ToggleShowPassButton>
      )}
    </InputContainer>
  )
}
