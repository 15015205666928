import React, {FormEvent, useState} from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { useAuth } from '../../hooks/auth'
import {Navigate, useLocation} from 'react-router-dom'
import { Form } from '../../components/Form'
import { Button } from '../../components/Form/Button'
import * as Yup from 'yup'
import { api } from '../../services/api'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import axios from 'axios'

const {REACT_APP_STORAGE_TOKEN} = process.env

export function EmailVerification() {
  const { loggued, accountVerified } = useAuth()
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  async function handleSendEmailVerification(event: FormEvent) {
    event.preventDefault()
    try {
      setLoading(true)
      const {data} = await api.post(
        `/send-email-verification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(String(REACT_APP_STORAGE_TOKEN))}`
          }
        }
      )
      // setLoading(false)
      ToastNotification({
        text: data.message,
        typeNotify: 'success'
      })
    } catch (e) {
      setLoading(false)
      if (e instanceof Yup.ValidationError) {
        ToastNotification({ text: e.message, typeNotify: 'warning' })
      }
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({ text: data.message, typeNotify: 'warning' })
            }
          }
        }
      }
    }
  }

  if (!loggued) {
    return <Navigate to={'/sign'} replace state={{ from: location }} />
  }
  if (loggued && accountVerified) {
    return <Navigate to={'/'} replace />
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Solicitar confirmação de conta no Danki Club</title>
      </Helmet>
      <Background>
        <Content>
          <Form title={'Solicitar verificação de conta'}>
            <h2>Para continuar acessando sua conta, é necessário confirmar seu e-mail.</h2>
            <div>
              <Button title={'Solicitar verificação'} disabled={loading} type={'button'}
              onClick={handleSendEmailVerification}
              />
            </div>
          </Form>
        </Content>
      </Background>
    </>
  )
}
