import styled from 'styled-components'

export const Container = styled.footer`
  background-color: var(--purple-100-rgba);
  width: 100%;
  box-shadow: var(--black) -2px 0 3px 0;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  place-items: center;
  grid-gap: 1rem;
  
  
  @media(max-width: 480px){
    padding: 20px;
  }

  backdrop-filter: blur(4px);
  
  img {
    width: 100%;
    max-width: 100px;
    justify-self: center;
  }
  
  p {
    color: var(--white);
    font-size: .75rem;
  }
`
