import styled from 'styled-components'

export const Container = styled.nav`
  position: relative;
  z-index: 10;
  width: 260px;

  > button {
    position: absolute;
    left: 20px;
    top: 20px;
    outline: none;
    background: transparent;
    border: none;

    img {
      transition: all 0.2s ease-in;
      width: 40px;
    }

    :hover {
      img {
        transition: all 0.2s ease-in;
        width: 50px;
        transform: rotate(180deg);
      }
    }
  }

  .nav-container {
    display: grid;
    grid-row-gap: 2rem;

    background: var(--purple-300-rgba);
    backdrop-filter: blur(10px);
    border: 1px solid var(--purple-200);
    border-radius: 8px;
    padding: 30px 0;

    overflow-y: auto;
    height: auto;

    ::-webkit-scrollbar {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--pink);
    }

    position: absolute;
    top: 20px;

    .logo-container {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;

      img {
        width: 100%;
        max-width: 145px;
      }

      button {
        //visibility: hidden;
        outline: none;
        background: transparent;
        border: none;
        
        @media(max-width: 768px) {
          //visibility: visible;
        }

        color: var(--purple);

        svg {
          font-size: 1.2rem;
          transition: all 0.2s ease-out;
          transform: translateX(0);
        }

        :hover {
          svg {
            transition: all 0.2s ease-in;
            transform: translateX(-2px);
            color: var(--white-400);
          }
        }
      }
    }

    .divisor {
      display: block;
      width: 100%;
      max-width: 200px;
      height: 1px;
      padding: 0 20px;
      background: rgb(216, 166, 240);
      background: radial-gradient(
        circle,
        rgba(216, 166, 240, 1) 0%,
        rgba(64, 7, 100, 0.1) 100%
      );
      justify-self: center;
    }

    .list {
      width: 100%;
      color: var(--white);
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 1rem;

      h2 {
        font-size: 0.75rem;
        color: var(--purple);
        padding: 0 20px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 100;
      }

      a,
      button {
        background: transparent;
        border: none;
        outline: none;

        font-size: 1rem;
        color: var(--white);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 0 0;
        font-weight: 600;
        height: 30px;
        text-shadow: var(--black) 1px 1px 2px;

        > div {
          position: relative;
          display: flex;
          align-items: center;

          .tag-border {
            border: none;
            width: 4px;
            height: 30px;
            margin-right: 17px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            transform: translateX(-3px);
            transition: all 0.2s ease-in-out;
            filter: blur(4px);
            position: relative;
            z-index: 1;
          }

          svg {
            margin-right: 20px;
            font-size: 1.2rem;
            z-index: 2;
            width: 30px;
          }
        }

        :hover {
          color: var(--white-400);

          svg {
            transition: 0.2s ease-in;
            transform: scale(1.05);
            color: var(--purple);
          }

          .tag-border {
            background: var(--purple);
            transform: translateX(0);
            transition: all 0.2s ease-in-out;
            box-shadow: var(--purple-100) 0 0 25px 15px;
            border-right: 1px solid var(--black-rgba);
          }
        }

        :active {
          background: transparent;
        }
      }

      a.active {
        .tag-border {
          background: var(--purple);
          transform: translateX(0);
          transition: all 0.2s ease-in-out;
          box-shadow: var(--purple-100) 0 0 25px 15px;
          border-right: 1px solid var(--black-rgba);
        }

        svg {
          color: var(--pink);
        }
      }

      span {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: var(--red);
        color: var(--white);
        font-size: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: var(--black-rgba) 1px 1px 4px 0;
      }

      a.active {
      }

      .profile {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          background-color: var(--white-400);
          width: 50px;
          height: 50px;
          border-radius: 25px;
          border: 4px solid var(--purple-300-rgba);
        }

        p {
          padding-left: 10px;
          font-size: 0.95rem;

          strong {
            display: block;
            font-size: 0.65rem;
            text-transform: uppercase;
            color: var(--gold-100);
            font-weight: 900;
          }
        }
      }
    }
  }

  .nav-container.hide {
    transform: translateX(-260px);
    transition: all 0.25s ease-in-out;
  }

  .nav-container.show {
    width: 260px;
    transition: all 0.25s ease-in-out;
    transform: translateX(20px);
  }
`
