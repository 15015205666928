import styled from 'styled-components'

export const CommentNotFound = styled.p`
  width: 100%;
  background-color: var(--purple-100-rgba);
  padding: 30px;
  margin: 10px 0;
  text-align: center;

  strong {
    display: block;
  }
`

export const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-areas: 'null response';

  @media (max-width: 720px) {
    grid-template-columns: 50px 1fr;
  }

  .comment-response {
    grid-area: response;
  }
`

export const BoxComment = styled.div`
  padding: 20px;
  background-color: var(--purple-300-rgba);

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    place-content: flex-end;

    input {
      border: 1px solid var(--purple-100);
      background-color: var(--purple-200);
      padding: 6px 10px;
      width: 100%;
      max-width: 400px;
      outline: none;
      color: var(--white);

      ::placeholder {
        color: var(--purple);
      }
    }

    button {
      border: none;
      padding: 6px 12px;
      color: var(--white);
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 700;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: var(--black-rgba);
      transition: all 0.2s;

      :hover {
        transition: all 0.2s;
        background-color: var(--pink);
      }
    }
  }
`
