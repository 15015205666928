import React, { FormEvent, useEffect, useState } from 'react'
import { Content } from './styles'
import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { api } from '../../services/api'
import { IFavoriteListDataProps } from '../../Interfaces/FavoriteListProps'
import { InputList } from '../../components/FavoriteAction/InputList'
import {
  IFavoriteVideoListProps,
  IListProps
} from '../../Interfaces/FavoriteVideoListProps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import { VideoCardSearch } from '../../components/VideoCardSearch'
import { Loading } from '../../components/Loading'
import { Button } from '../../components/Form/Button'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'

import { confirmAlert } from 'react-confirm-alert'
import axios from 'axios'
import { useAuth } from '../../hooks/auth' // Import

const { REACT_APP_STORAGE_TOKEN } = process.env

export function Bookmarker() {
  const { signOut } = useAuth()
  const [loading, setLoading] = useState(false)
  const [favoriteList, setFavoriteList] = useState(
    {} as IFavoriteListDataProps[]
  )
  const [videos, setVideos] = useState({} as IFavoriteVideoListProps)
  const [list, setList] = useState({} as IListProps)
  const [listName, setListName] = useState('')

  function pushNewItem(data: IListProps) {
    setFavoriteList((old) => [data, ...old])
  }

  async function loadList(id: number, name: string) {
    try {
      setLoading(true)
      const { data } = await api.get(`/favorite/video-list/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            String(REACT_APP_STORAGE_TOKEN)
          )}`
        }
      })
      setVideos(data)
      setList({ id, name })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function handleSubmitUpdate(form: FormEvent) {
    try {
      form.preventDefault()
      if (listName.length < 3) {
        return ToastNotification({
          text: 'Digite um nome válido para sua lista',
          typeNotify: 'warning'
        })
      }
      setLoading(true)
      const { data: listUpdate } = await api.patch(
        `/favorite/${list.id}`,
        {
          name: listName
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      getList().then()
      setList({ id: list.id, name: listName })
      setLoading(false)
      return ToastNotification({
        text: listUpdate.message,
        typeNotify: 'success'
      })
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function getList() {
    try {
      setLoading(true)
      const { data } = await api.get(`/favorite`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            String(REACT_APP_STORAGE_TOKEN)
          )}`
        }
      })
      setFavoriteList(data.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function deleteList(id: number) {
    try {
      setLoading(true)
      const { data } = await api.delete(`/favorite/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            String(REACT_APP_STORAGE_TOKEN)
          )}`
        }
      })
      setList({} as IListProps)
      setVideos({} as IFavoriteVideoListProps)
      getList().then(() =>
        ToastNotification({
          text: data.message
        })
      )
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function handleDeleteList(id: number) {
    try {
      confirmAlert({
        title: 'Atenção',
        message:
          'Ao remover esta lista, todos os vídeos favoritos contidos nela serão perdidos, deseja continuar?',
        buttons: [
          {
            label: 'Sim',
            className: 'confirm',
            onClick: () => deleteList(id)
          },
          {
            label: 'Não',
            className: 'recuse',
            onClick: () => {}
          }
        ],
        overlayClassName: 'overlay-custom-class-name'
      })
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await getList()
    })()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Meus vídeos Favoritos - Danki Club" />
        <title>Meus vídeos Favoritos Danki Club</title>
      </Helmet>
      <Background>
        <h1>Favoritos</h1>
        <Content>
          <section className={'background-transparency'}>
            <h2>Gerencia suas lista de vídeos favoritos</h2>
            <div>
              <nav>
                <InputList handleUpdateList={pushNewItem} />
                <ul>
                  {favoriteList.length > 0 ? (
                    favoriteList.map((item) => (
                      <li
                        className={item.id === list.id ? 'active' : ''}
                        key={item.id}
                      >
                        <span onClick={() => loadList(item.id, item.name)}>
                          <FontAwesomeIcon icon={faStar} />{' '}
                          {item.name.substring(0, 17)}
                          {item.name.length > 17 && '...'}
                        </span>
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => handleDeleteList(item.id)}
                        />
                      </li>
                    ))
                  ) : (
                    <small>Crie sua primeira lista</small>
                  )}
                </ul>
              </nav>
              <article>
                {list.id && (
                  <form onSubmit={handleSubmitUpdate}>
                    <label htmlFor="listName">Editar nome da lista</label>
                    <input
                      type="text"
                      name={'listName'}
                      onChange={(event) => setListName(event.target.value)}
                      defaultValue={list.name}
                      disabled={loading}
                    />
                    <Button title={'Atualizar'} type={'submit'} />
                  </form>
                )}

                {Object.keys(videos).length > 0 && videos.total > 0 ? (
                  <div className={'list-videos'}>
                    {videos.data.map((item, id) => (
                      <VideoCardSearch
                        key={item.id}
                        title={item.video.title}
                        video_id={item.video.id}
                        itemId={String(id)}
                        cover={item.video.cover_url}
                        large
                        cover_origin={item.video.cover}
                      />
                    ))}
                  </div>
                ) : videos.total === 0 ? (
                  <h3>A lista não possui vídeos.</h3>
                ) : (
                  <h3>Selecione uma lista para gerenciar</h3>
                )}
              </article>
            </div>
          </section>
        </Content>
      </Background>
    </>
  )
}
