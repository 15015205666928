import React, { FormEvent, useEffect, useState } from 'react'
import { CommentNotFound, Container, BoxComment } from './styles'
import { api } from '../../services/api'
import { ICommentProps } from '../../Interfaces/CommentProp'
import { Loading } from '../Loading'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import axios from 'axios'
import { List } from './List'

const { REACT_APP_STORAGE_TOKEN } = process.env

interface IComment {
  video: number
}

export function Comment({ video }: IComment) {
  const [comments, setComments] = useState({} as ICommentProps)
  const [loading, setLoading] = useState(true)
  const [comment, setComment] = useState('')

  async function handleOnSubmit(event: FormEvent, id: number) {
    event.preventDefault()
    if (comment.length < 3) {
      return ToastNotification({
        text: 'Digite pelo menos 3 caractéres em seu comentário',
        typeNotify: 'info'
      })
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/comments/video/response/${video}`,
        {
          content: comment,
          response_id: id
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setComment('')
      setLoading(false)
      return ToastNotification({
        text: data.message,
        typeNotify: 'success'
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
      }
      return ToastNotification({
        text: 'Falha ao enviar o comentário, tente novamente mais tarde ou entre em contato com o suporte.',
        typeNotify: 'error'
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!Object.keys(comments).length && loading) {
        try {
          const { data } = await api.get(`/comments/video/${video}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                String(REACT_APP_STORAGE_TOKEN)
              )}`
            }
          })
          setComments(data)
          setLoading(false)
        } catch (e) {}
      }
    })()
  }, [video, comments, loading])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      {Object.keys(comments).length && comments.data.length > 0 ? (
        comments.data.map((data) => (
          <BoxComment key={data.id}>
            <List
              id={data.id}
              full_name={data.full_name}
              extense_date={data.extense_date}
              content={data.content}
              customer_cover={data.customer_cover}
            />
            <Container>
              {data.comments.length > 0 && (
                <div className={'comment-response'}>
                  {data.comments.map((item) => (
                    <List
                      key={item.id}
                      id={item.id}
                      full_name={item.full_name}
                      extense_date={item.extense_date}
                      content={item.content}
                      customer_cover={item.customer_cover}
                    />
                  ))}
                </div>
              )}
            </Container>
            <form
              autoComplete={'off'}
              onSubmit={(event) => handleOnSubmit(event, data.id)}
            >
              <input
                type="text"
                autoComplete={'off'}
                name={'comment'}
                onChange={(e) => setComment(e.target.value)}
                placeholder={'Adicionar um comentário...'}
                disabled={loading}
              />
              <button type={'submit'} disabled={loading}>
                Responder
              </button>
            </form>
          </BoxComment>
        ))
      ) : (
        <CommentNotFound>
          O Vídeo ainda não possui comentários,{' '}
          <strong>seja o primeiro a comentar!</strong>
        </CommentNotFound>
      )}
    </div>
  )
}
