import styled from 'styled-components'

interface IVideoCropProps {
  cover: string
}

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  display: grid;
  margin: 0 auto;

  height: 100%;
  position: relative;

  > h1 {
    text-align: center;
    padding: 0 50px;
  }

  .settings {
    margin-top: 10px;
    background-color: var(--purple-300);
    width: 100%;
    padding: 20px;
    border-radius: 6px;

    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 720px) {
      grid-row-gap: 2rem;
      grid-template-columns: 1fr;
    }

    .author {
      width: 100%;
      font-size: 0.85rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      grid-column-gap: 1rem;

      @media (max-width: 720px) {
        justify-content: center;
      }

      .profile {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 25px;

        img {
          width: 100%;
          height: auto;
        }
      }

      span {
        display: block;
        font-weight: 700;
      }
    }

    .buttons-container {
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fit, 200px);
      justify-content: right;
      align-items: center;

      button {
        margin: 0;
        font-size: 0.8rem;
        padding: 10px 10px;
      }

      @media (max-width: 720px) {
        grid-template-columns: 1fr;
        justify-content: center;
      }

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .data-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 10px;

    .attention {
      color: var(--gold-100);
      text-align: center;
      text-transform: uppercase;
      margin-top: 30px;
    }
    .prevision {
      margin: 20px 0;
      text-align: center;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      > article {
        width: 100% !important;
        justify-content: left;

        :nth-child(1) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    article {
      background-color: var(--purple-300);
      width: 50%;
      padding: 20px;
      border-radius: 6px;

      :nth-child(1) {
        margin-right: 10px;
      }

      header {
        margin-bottom: 10px;
      }

      > div {
        p {
          line-height: 22px;
          margin-bottom: 10px;
        }

        ul {
          padding: 20px 30px;
        }
      }
    }
  }

  .messages {
    margin-top: 10px;

    h2 {
      background-color: var(--black-rgba);
      padding: 10px;
      border-radius: 4px;
      text-align: center;
      font-size: 1.2rem;
      margin: 20px 0;
    }

    .form-comment {
      display: grid;
      grid-template-columns: 100px 1fr;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--purple);
      margin-top: 30px;
      grid-column-gap: 2rem;
      padding: 30px 0;

      .picture {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background-color: var(--purple);
        overflow: hidden;
        border: 1px solid var(--purple-200);
        box-shadow: 0 0 4px var(--black-rgba);

        img {
          width: 100px;
          height: auto;
        }
      }

      form {
        width: 100%;
        display: block;

        label {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }

        textarea {
          display: block;
          width: 100%;
          outline-color: var(--purple-300);
          border-radius: 4px;
          border: 1px solid var(--purple-100);
          font-size: 0.85rem;
          color: var(--purple-300);
          padding: 10px 14px;
        }

        > div {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          button {
            font-size: 0.85rem;
            margin: 5px 0;
            padding: 10px 20px;
          }
        }
      }
    }
  }
`

export const VideoCrop = styled.div<IVideoCropProps>`
  margin-top: 20px;

  border: 10px solid var(--purple-100-rgba);
  background-color: var(--purple-300-rgba);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  box-shadow: var(--black-rgba) 3px 1px 4px 0;

  overflow: hidden;
  position: relative;
`

export const ModalContent = styled.form`
  width: 100%;
  display: grid;
  grid-row-gap: 1rem;
  padding-bottom: 20px;

  h1 {
    text-align: center;
    font-weight: 400;
    font-size: 1.4rem;
  }

  p {
    font-size: 1.1rem;
    margin: 20px 0;
  }

  .form-control {
    display: grid;
    grid-template-columns: 1fr;

    label {
      display: block;
      width: 100%;
      text-transform: uppercase;
      font-size: 0.85rem;
      margin-bottom: 10px;
    }

    > p {
      width: 100%;
      font-size: 0.9rem;
      margin: 4px 0;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    input {
      font-size: 0.85rem;
      color: var(--purple-300);
      padding: 10px 14px;
      border-radius: 4px;
      border: 1px solid var(--purple-100);
      width: 100%;
      position: relative;
      outline: none;

      ::placeholder {
        color: var(--white-400);
        text-transform: uppercase;
      }
    }

    textarea {
      display: block;
      width: 100%;
      outline-color: var(--purple-300);
      border-radius: 4px;
      border: 1px solid var(--purple-100);
      font-size: 0.85rem;
      color: var(--purple-300);
      padding: 10px 14px;

      ::placeholder {
        color: var(--white-400);
        text-transform: uppercase;
      }
    }
  }

  > div {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      font-size: 0.85rem;
      margin: 5px 0;
      padding: 10px 20px;
    }
  }
`
