import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { Button } from './styles'

interface IArrowButtonProps {
  children: ReactNode
  disabled: boolean
  onClick: () => void
}

interface IRightProps {
  limit: number
  pushNewItems: () => void
}

export function Arrow({ children, disabled, onClick }: IArrowButtonProps) {
  return (
    <Button disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  )
}

export function Left() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  )
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators])

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </Arrow>
  )
}

export function Right({ limit, pushNewItems }: IRightProps) {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
    items
  } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  )

  useEffect(() => {
    if (isLastItemVisible) {
      pushNewItems()
    }
    if (items.toItemsWithoutSeparators().length >= limit) {
      setDisabled(isLastItemVisible)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, limit, isLastItemVisible])

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <FontAwesomeIcon icon={faChevronRight} />
    </Arrow>
  )
}
