import {toast, Zoom} from 'react-toastify'

interface Props {
  text: string
  typeNotify?: "info" | "success" | "warning" | "error" | "default"
  autoClose?: number
}

function ToastNotification({ text, typeNotify = 'info', autoClose = 5000 }: Props) {
  toast(text, {
    position: 'bottom-right',
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    type: typeNotify,
    transition: Zoom,

  })
}

function ToastMultipleNotification(dataErrors: any) {
  for (let k in dataErrors) {
    if (dataErrors.hasOwnProperty(k)) {
      ToastNotification({
        text: `${dataErrors[k]}`,
        typeNotify: 'warning'
      })
    }
  }
}

export { ToastNotification, ToastMultipleNotification }
