import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  span {
    width: 100%;
    color: var(--red);
    font-size: 0.85rem;
    margin-bottom: 10px;
    display: block;
  }
`
