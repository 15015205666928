import React, { ButtonHTMLAttributes } from 'react'
import { Container } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  color?: string
  enabled?: boolean
}

export function Button({ title, color, enabled = false, ...rest }: Props) {

  return (
    <Container disabled={enabled} color={color} {...rest}>
      {title}
    </Container>
  )
}
