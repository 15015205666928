import styled from "styled-components";

export const Container = styled.form`
  padding: 40px;
  border: 4px solid var(--purple-100-rgba);
  background-color: var(--purple-300-rgba);
  border-radius: 4px;
  width: 100%;
  max-width: 440px;
  display: grid;
  justify-items: center;

  h1 {
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 1rem;
    font-weight: 400;
    margin: 5px 0;
  }

  img {
    width: 100%;
    max-width: 180px;
    margin-bottom: 20px;
  }

  div {
    width: 100%;
    margin-top: 10px;


    label {
      display: block;
      margin-bottom: 5px;

      font-size: 0.75rem;
      text-transform: uppercase;
    }

  }

  p {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    a {
      text-transform: uppercase;
      font-size: 0.8rem;

      :hover {
        color: var(--pink);
      }
    }
  }
`;
