import React, { useEffect, useState } from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { api } from '../../services/api'
import { IInvoiceProp } from '../../Interfaces/InvoiceProp'
import axios from 'axios'
import { useAuth } from '../../hooks/auth'
import { ToastNotification } from '../../services/toast'
import {NavLink} from "react-router-dom";
import {faChevronCircleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Loading} from "../../components/Loading";

const { REACT_APP_STORAGE_TOKEN } = process.env

export function Invoices() {
  const { signOut, user } = useAuth()
  const [invoices, setInvoices] = useState({} as IInvoiceProp)
  // const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const { data } = await api.get(`/invoices?page=1&per_page=30`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        })
        setInvoices(data)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        if (axios.isAxiosError(e)) {
          // @ts-ignore
          if (e.response.status === 401) {
            return signOut().then()
          }
        }
        return ToastNotification({
          text: 'Não foi possível concluir esta requisição, tente novamente mais tarde.',
          typeNotify: 'error'
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Loading isLoading={loading} />
      <Helmet>
        <meta name="description" content="Faturas Danki Club" />
        <title>Faturas - Danki Club</title>
      </Helmet>
      <Background>
        <h1>Faturas</h1>
        <Content>
          <section className={'background-transparency'}>
            <div className="plan">
              <div className="picture">
                <img src={user.url_cover} alt={user.full_name} />
              </div>
              <h1>Olá {user.first_name}</h1>
              <p>
                Você está no plano: <span className={'text-gold uppercase'}>Danki <strong>{user.plan}</strong></span>
              </p>
              <p>Seu acesso está: {user.status
                  ? <span className={'text-green uppercase'}><strong>Liberado</strong> até {user.access_limit}</span> :
                  <span className={'text-red uppercase animate-scale'}><strong>Bloqueado</strong></span>
              }
              </p>
              {
                !user.status && <p className={'focus'}>Verfique seu último pagamento</p>
              }
              {user.plan_beneficies && user.status && (
                <div>
                  <h3>Seus beneficios ativos:</h3>
                  <p><FontAwesomeIcon icon={faChevronCircleRight} /> <span>Encomendar vídeos</span></p>
                </div>
              )}
            </div>
            <div className="invoices">
              <div>
                <h2>Confira suas faturas</h2>
                <p>
                  Caso exista alguma fatura em aberto, seu acesso ficará bloqueado
                  para navegar nos recursos da plataforma.
                </p>
                <p>
                  Qualquer problema que encontre, você pode entrar em contato com nosso <NavLink to={'/support'}>suporte</NavLink>.
                </p>
                <p>
                  <small>
                    Algumas faturas podem levar algum tempo até que a Hotmart
                    notifique nosso sistema.
                  </small>
                </p>
              </div>
              <div className="table-responsive">
                <table className={'table table-striped table-hover'}>
                  <thead>
                    <tr>
                      <th>Fatura</th>
                      <th>Plano contratado</th>
                      <th>Status</th>
                      <th>Data da cobrança</th>
                      <th>Valor pago</th>
                      <th>Acesso ao conteúdo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.data &&
                      invoices.data.length > 0 &&
                      invoices.data.map((invoice) => (
                        <tr key={invoice.id}>
                          <td align={"center"}>{invoice.transaction}</td>
                          <td align={"center"} className={'text-gold uppercase'}>Danki <strong>{invoice.subscription_plan_name}</strong></td>
                          <td align={"center"}>{invoice.status}</td>
                          <td align={"center"}>{invoice.order_date}</td>
                          <td align={"center"}>{invoice.price}</td>
                          <td align={"center"}>
                            {invoice.status === 'APPROVED'
                              ? 'Liberado'
                              : 'Bloqueado'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </Content>
      </Background>
    </>
  )
}
