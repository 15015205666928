import styled from 'styled-components'

export const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 300px;
  
  @media(max-width: 480px){
    width: 200px;
  }

  background-color: var(--white);
  border-radius: 4px;
  padding: 2px;
  border: 2px solid var(--purple-200);

  input {
    font-size: 0.85rem;
    color: var(--purple-300);
    padding: 8px 10px;
    border: none;
    outline: none;
    background-color: var(--white);
    
    ::placeholder {
      color: var(--white-400);
      text-transform: uppercase;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;

    svg {
      color: var(--purple-200);
      font-size: 1.4rem;
    }
  }
`

export const Button = styled.button`
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 30px;
  background-color: transparent;
  color: var(--white);
  border: none;
  outline: none;

  svg {
  transition: all 0.2s ease-in;
    font-size: 1.6rem;

    :hover {
  transition: all 0.2s ease-in;
      color: var(--purple);
      transform: scale(1.1);
    }
  }
`
