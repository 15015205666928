import React, { FormEvent, useState } from 'react'
import { Container } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { ToastNotification } from '../../../services/toast'
import { api } from '../../../services/api'
import { IListProps } from '../../../Interfaces/FavoriteVideoListProps'
import LoadingSvg from '../../../assets/images/loading.svg'

const { REACT_APP_STORAGE_TOKEN } = process.env

interface Props {
  handleUpdateList: (data: IListProps) => void
}

export function InputList({ handleUpdateList }: Props) {
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleSubmit(form: FormEvent) {
    form.preventDefault()
    if (name.length < 3) {
      return ToastNotification({
        text: 'Digite um nome válido para sua lista',
        typeNotify: 'warning'
      })
    }
    setLoading(true)
    const { data } = await api.post(
      `/favorite`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            String(REACT_APP_STORAGE_TOKEN)
          )}`
        }
      }
    )
    setName('')
    handleUpdateList({ id: data.id, name: data.name, is_favorite: false })
    setLoading(false)
  }

  return (
    <Container onSubmit={handleSubmit} autoComplete={'off'}>
      <h2>Nova Lista</h2>
      <div className="form-control">
        <input
          autoComplete={'off'}
          type="text"
          name={'name'}
          placeholder={'Nome da lista'}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <button type={'submit'} disabled={loading}>
          {loading ? (
            <img src={LoadingSvg} alt="..." />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
      </div>
    </Container>
  )
}
