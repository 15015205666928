import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: relative;

  > button {
    width: 100%;
    padding: 12px 40px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    color: var(--white);
    font-weight: bold;
    transition: 0.2s;
    position: relative;
    z-index: 2;
    margin-bottom: 8px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: var(--purple-100);
    font-size: 0.95rem;

    svg {
      margin-right: 5px;
      color: var(--yellow-100);
    }

    :hover {
      transition: 0.2s;
      background-color: var(--purple-200);
    }
  }

  > div {
    top: 30px;
    width: 100%;
    background-color: var(--purple-400);
    position: absolute;
    z-index: 1;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
    display: grid;
    padding: 12px;

    img {
      width: 50px;
    }

    ul {
      display: grid;
      grid-row-gap: 0.8rem;
      width: 100%;

      li {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          cursor: pointer;
          margin-left: 15px;
          text-transform: uppercase;
          font-size: 0.75rem;
          color: var(--white);
          width: 100%;

          @media (max-width: 768px) {
            font-size: 0.9rem;
          }
        }

        input[type='checkbox'] {
          position: relative;
          cursor: pointer;

          :before {
            content: '';
            display: block;
            position: absolute;
            width: 15px;
            height: 15px;
            top: 0;
            left: 0;
            border: 2px solid var(--purple-100);
            border-radius: 1px;
            background-color: var(--purple-100);
          }

          :checked:after {
            content: '';
            display: block;
            width: 4px;
            height: 10px;
            border: solid var(--white);
            border-width: 0 2px 2px 0;
            position: absolute;
            top: 2px;
            left: 7px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  div.show {
    transform: translateY(0);
    transition: all 0.25s ease-in-out;
  }

  div.hide {
    transition: all 0.25s ease-in-out;
    transform: translateY(-30px);
    z-index: -1;
    height: 0;
  }
`
