import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  margin-top: 20px;

  .background-transparency {
    background-color: var(--purple-200-rgba);
    padding: 20px;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, 420px);
    grid-gap: 1rem;
    place-content: space-around;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    .not-found {
        font-weight: 900;

      span {
        font-style: italic;
      }
    }
  }
`

export const SearchBox = styled.div`
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;

  h1 {
    padding-left: 40px;
    text-transform: capitalize;

    @media (max-width: 760px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
`
