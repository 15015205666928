import styled from 'styled-components'

export const Container = styled.form`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .form-control {
    display: grid;
    position: relative;
    overflow: hidden;
    align-items: center;

    input {
      font-size: 0.75rem;
      color: var(--purple-300);
      padding: 8px 35px 8px 5px;
      border: none;
      width: 100%;
      position: relative;
      outline: none;

      ::placeholder {
        color: var(--white-400);
        text-transform: uppercase;
      }
    }

    button {
      width: auto;
      padding: 12px 15px;
      text-align: center;
      text-transform: uppercase;
      border: none;
      color: var(--white);
      font-weight: bold;
      transition: 0.2s;
      z-index: 2;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      background-color: var(--purple-100);
      font-size: 0.95rem;
      display: flex;
      position: absolute;
      right: 0;

      img {
        width: 100%;
        max-width: 20px;
      }
    }
  }
`
