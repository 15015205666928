import React, { useEffect, useRef, useState } from 'react'
import { Container } from './styles'
import { Helmet } from 'react-helmet'
import { HorizontalScroll } from '../../components/HorizontalScroll'

import VideoMp4 from '../../assets/images/hero-home-video.mp4'
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api } from '../../services/api'
import { IVideoProps } from '../../Interfaces/VideoProps'
import { ToastNotification } from '../../services/toast'
import { ITagProps } from '../../Interfaces/TagProps'
import axios from 'axios'
import { useAuth } from '../../hooks/auth'
import { Loading } from '../../components/Loading'

const { REACT_APP_STORAGE_TOKEN } = process.env

export function Home() {
  const [loading, setLoading] = useState(true)
  const { signOut } = useAuth()
  const [volumeMuted, setVolumeMuted] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  const [tags, setTags] = useState({} as ITagProps)
  const [laucherAt, setLaucherAt] = useState({} as IVideoProps)
  const [pageLaucherAt, setPageLaucherAt] = useState(1)
  const [highlights, setHighlights] = useState({} as IVideoProps[])
  const [pageHighlights, setPageHighlights] = useState(1)
  const [myContinue, setMyContinue] = useState({} as IVideoProps)
  const [pageMyContinue, setPageMyContinue] = useState(1)

  async function handleGetHighlights(tag: number) {
    try {
      const { data } = await api.get(
        `/videos?page=1&per_page=15&category=highlights&tag=${tag}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setHighlights((oldData) => {
        return oldData.length === undefined ? [data] : [...oldData, data]
      })
    } catch (e) {
      //
    }
  }

  async function pushNewItemsHighlights(index: number, tag: number) {
    // try {
    //   if (highlights[index].data.length >= 15) {
    //     return false
    //   }
    //   const pagePlus = pageHighlights + 1
    //   setPageHighlights(pagePlus)
    //   const { data } = await api.get(
    //     `/videos?page=${pagePlus}&per_page=15&category=highlights&tag=${tag}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem(
    //           String(REACT_APP_STORAGE_TOKEN)
    //         )}`
    //       }
    //     }
    //   )
    //   const { data: videos } = data
    //   const oldItems = highlights[index].data
    //   const newItems = oldItems.concat(videos)
    //   const allItems = highlights.map((items, id) => {
    //     return id === index ? [newItems] : [highlights[id]]
    //   })
    //   // setHighlights(old => [...old, old[index].data: oldItems.concat(videos)])
    //   setHighlights(allItems)
    // } catch (e) {
    //   ToastNotification({
    //     text: 'Nenhum novo vídeo encontrado.',
    //     typeNotify: 'warning'
    //   })
    // }
  }

  async function handleGetLaucherAt() {
    try {
      const { data } = await api.get(
        `/videos?page=${pageLaucherAt}&per_page=15&category=laucher_at`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setLaucherAt(data)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function pushNewItemsLaucherAt() {
    try {
      if (laucherAt.data.length >= 30) {
        return false
      }
      const pagePlus = pageLaucherAt + 1
      setPageLaucherAt(pagePlus)
      const { data } = await api.get(
        `/videos?page=${pagePlus}&per_page=15&category=laucher_at`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      const { data: videos } = data
      const newItems = laucherAt.data
      setLaucherAt({
        ...data,
        data: newItems.concat(videos)
      })
    } catch (e) {
      ToastNotification({
        text: 'Nenhum novo vídeo encontrado.',
        typeNotify: 'warning'
      })
    }
  }

  async function handleGetMyContinue() {
    try {
      const { data } = await api.get(
        `/videos?page=${pageMyContinue}&per_page=15&category=continue`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setMyContinue(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      //
    }
  }

  async function pushNewItemsMyContinue() {
    try {
      if (myContinue.data.length >= 15) {
        return false
      }
      const pagePlus = pageMyContinue + 1
      setPageMyContinue(pagePlus)
      const { data } = await api.get(
        `/videos?page=${pagePlus}&per_page=15&category=continue`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      const { data: videos } = data
      const newItems = myContinue.data
      setMyContinue({
        ...data,
        data: newItems.concat(videos)
      })
    } catch (e) {
      ToastNotification({
        text: 'Nenhum novo vídeo encontrado.',
        typeNotify: 'warning'
      })
    }
  }

  async function getCategories() {
    try {
      const { data } = await api.get(
        `/videos/tags?highlights=true&per_page=3`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setTags(data)
    } catch (e) {
      //
    }
  }

  function onMuted() {
    if (!videoRef.current) {
      return
    }
    const { muted } = videoRef.current

    videoRef.current.muted = !muted
    setVolumeMuted(!muted)
  }

  useEffect(() => {
    getCategories().then()
  }, [])

  return (
    <>
      <Loading isLoading={loading} />
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Dashboard Danki Club</title>
      </Helmet>

      <Container>
        <div className={'laucher-at'}>
          <section className="vd-crop">
            <div className="vd-container">
              <video
                playsInline
                controls={false}
                // poster={PosterJpg}
                src={VideoMp4}
                preload="metadata"
                autoPlay
                muted
                loop
                ref={videoRef}
              />
            </div>
            <button
              type={'button'}
              className="volume-control"
              onClick={onMuted}
            >
              <FontAwesomeIcon
                icon={!volumeMuted ? faVolumeUp : faVolumeMute}
              />
            </button>
          </section>
          <section className={'updates'}>
            <HorizontalScroll
              title={'Lançamentos'}
              largeCard
              handleRequest={handleGetLaucherAt}
              items={laucherAt.data}
              newItemsLimit={30}
              fetchItems={pushNewItemsLaucherAt}
            />
          </section>
        </div>
        <section className={'continue'}>
          <HorizontalScroll
            title={'Continue assistindo'}
            handleRequest={handleGetMyContinue}
            items={myContinue.data}
            fetchItems={pushNewItemsMyContinue}
            largeCard={false}
            newItemsLimit={15}
          />
        </section>
        <div className={'highs-tags'}>
          <h2>Vídeos mais assistidos</h2>
        </div>
        {tags.total > 0 &&
          tags.data.map((tag, id) => (
            <section className={'highs'} key={id}>
              <HorizontalScroll
                title={tag.name}
                subtitle
                handleRequest={() => handleGetHighlights(tag.id)}
                items={highlights[id]?.data}
                newItemsLimit={15}
                largeCard={id === 0}
                fetchItems={() => pushNewItemsHighlights(id, tag.id)}
              />
            </section>
          ))}
      </Container>
    </>
  )
}
