import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  grid-column-gap: 1rem;
  margin-bottom: 10px;

  @media (max-width: 580px) {
    grid-template-columns: 50px 1fr;
  }

  .text {
    background: var(--purple-300-rgba);
    backdrop-filter: blur(10px);
    border: 1px solid var(--purple-200);
    width: 100%;
    margin-right: 10px;
    padding: 20px 30px;
    border-radius: 4px;

    :hover {
      box-shadow: 0 0.1rem 1rem var(--black-rgba);
    }

    @media (max-width: 768px) {
      padding: 20px;
    }

    .user-coment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.85rem;
      margin-bottom: 10px;
      font-weight: 600;

      svg {
        margin-right: 5px;
        font-size: 1rem;
      }

      .user {
        display: flex;
        align-items: center;
        text-transform: uppercase;
      }

      .date {
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }
    }

    section {
      padding: 0 20px;

      a {
        color: var(--pink);
        text-decoration: underline;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .picture {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: var(--purple);
    overflow: hidden;
    border: 1px solid var(--purple-200);
    box-shadow: 0 0 4px var(--black-rgba);

    img {
      width: 80px;
      height: auto;
    }

    @media (max-width: 580px) {
      width: 50px;
      height: 50px;
      border-radius: 25px;

      img {
        width: 50px;
      }
    }
  }
`
