import styled from "styled-components";

interface IPropsVideoCard {
    backgroundImg: string
    largeTemplate: boolean
}

export const Container = styled.div<IPropsVideoCard>`
  
  .card {
    background-color: var(--black);
    background-image: url("${({backgroundImg}) => backgroundImg}");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    box-shadow: var(--black) 0 0 3px 0;
    
    margin-right: 5px;
    border-radius: 6px;
    width: ${({largeTemplate}) => largeTemplate ? 420 : 220}px;
    height: ${({largeTemplate}) => largeTemplate ? 260 : 125}px;
    position: relative;
    
    padding: 5px;
    display: flex;

    transition: all .3s;

    :hover {
      transform: scale(1.01);
      transition: all .2s;
    }
    
    h2 {
      background-color: var(--black-rgba);
      width: 100%;
      height: 100%;
      font-weight: 900;
      text-align: right;
      font-size: ${({largeTemplate}) => largeTemplate ? 1.1 : .85}rem;
      text-transform: capitalize;
    }

    span {
      background: var(--pink);
      font-size: .65rem;
      padding: 5px 10px;
      border-radius: 20px;
      position: absolute;
      left: 10px;
      bottom: 10px;
    }
    
    @media(max-width: 480px) {
      width: ${({largeTemplate}) => largeTemplate ? 280 : 220}px;
      height: ${({largeTemplate}) => largeTemplate ? 160 : 120}px;
    }
  }
`;
