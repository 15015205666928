import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --white: #ffffff;
    --white-100: #f3f3f3;
    --white-200: #fafcff;
    --white-300: #e2e3e4;
    --white-400: #cbd2e0;


    --gold: #efda9f;
    --gold-100: #d0b877;
    --gold-200: #ac924d;
    --gold-300: #635530;

    --yellow: #b8950a;
    --yellow-100: #d6b326;

    --blue: #1a325f;
    --blue-900: #020a19;

    --purple: #8789b9;
    --purple-100: #5e3ea1;
    --purple-200: #4c357d;
    --purple-300: #240a53;
    --purple-400: #120c2b;

    --purple-100-rgba: rgba(94, 62, 161, 0.1);
    --purple-200-rgba: rgb(46, 34, 74, 0.5);
    --purple-300-rgba: rgb(46, 34, 74, 0.7);

    --red: #e65433;
    --red-400: #633030;

    --green: #faffde;
    --green-100: #bde100;
    --green-200: #99d939;
    --green-300: #5a9418;
    --green-400: #063b17;

    --pink: #d83964;

    --black: #111111;
    --black-rgba: rgba(0, 0, 0, .6);

    --roboto: 'Roboto';
    --montserrat: 'Montserrat';
    --rumraisin: 'Rum Raisin';
    --lato: 'Lato';
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1280px) {
      font-size: 93.75%
    }

    @media (max-width: 720px) {
      font-size: 87.5%
    }

    @media (max-width: 480px) {
      font-size: 77.5%
    }
  }

  body {
    color: var(--white);
    background: var(--purple-200);
    -webkit-font-smoothing: antialiased;
    height: 100vh;

    #root {
      height: 100%;
    }

  }

  body, input, textarea, button {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: ease-in .2s;

    &:hover {
      transition: ease-out .3s;
    }
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .react-modal-overlay {
    background: var(--black-rgba);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }

  .react-modal-content {
    background-color: var(--purple-300);
    padding: 60px;
    position: absolute;
    border-radius: 5px;
    overflow: auto;
    inset: 60px;

    @media (max-width: 720px) {
      padding: 30px 20px;
      inset: 20px;
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    border: 0;
    background: transparent;
    font-size: 2rem;
    color: var(--purple);
    transition: ease-in .2s;

    &:hover {
      transition: ease-out .4s;
      color: var(--pink);
    }
  }

  .Toastify__toast-theme--dark {
    background: var(--purple-300);
    color: var(--toastify-text-color-dark);
  }

  .overlay-custom-class-name {
    background-color: var(--black-rgba);

    .react-confirm-alert-body {
      background-color: var(--purple-200);
      font-family: inherit;
      width: 400px;
      padding: 30px;
      border-radius: 4px;
      box-shadow: 0 20px 75px var(--purple-300-rgba);
      color: var(--white);

      h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      .react-confirm-alert-button-group {
        
        button {
          padding: 10px 30px;
          text-align: center;
          text-transform: uppercase;
          border: none;
          border-radius: 4px;
          color: var(--white);
          font-weight: bold;
          transition: 0.2s;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          
        }

        .confirm {
          background-color: var(--black);
          :hover {
            transition: 0.2s;
            background-color: var(--green-300);
          }
        }
        .recuse {
          background-color: var(--black);
          :hover {
            transition: 0.2s;
            background-color: var(--red);
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--purple-200);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--purple);
    border-radius: 5px;

    :hover {
      background-color: var(--pink);
    }
  }

  .text-gold {
    color: var(--gold-100);
  }

  .text-red {
    color: var(--red);
  }

  .text-green {
    color: var(--green-300);
  }

  .uppercase {
    text-transform: uppercase;
    font-size: .90rem;
  }

  .animate-scale {
    @keyframes scale {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }

    transform: scale(1);
    animation: scale .8s ease-in-out infinite;
  }

  .btn {
    padding: 10px 20px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: .80rem;
    font-weight: 700;
    display: block;
    text-align: center;
    color: var(--black);
    background-color: var(--gold-200);

    :hover {
      background-color: var(--gold-100);
    }
  }

`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 820px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  main {
    width: 100%;
    flex: 1;
  }
`

export { GlobalStyle, Container }
