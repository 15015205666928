import React, { useEffect } from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {faBusinessTime, faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";

export function Support() {
  useEffect(() => {

  }, [])

  return (
    <>
      <Helmet>
        <meta name="description" content="Suporte - Danki Club" />
        <title>Suporte - Danki Club</title>
      </Helmet>
      <Background>
        <h1>Suporte Danki Club</h1>
        <Content>
          <section className={'background-transparency'}>
            <h2>Canais de suporte</h2>

            <div>
              <article>
                <header>
                  <h1>Atendimento ao cliente</h1>
                </header>
                <p><FontAwesomeIcon icon={faEnvelope}/> <strong>E-mail:</strong> atendimento@dankicode.com</p>
                {/*<p><FontAwesomeIcon icon={faPhoneAlt}/> <strong>Telefone/WhatsApp:</strong> (48) 99807-5162</p>*/}
                <p><FontAwesomeIcon icon={faBusinessTime}/> De segunda a sexta</p>
              </article>
              <footer>
                <header>
                  <h1>Sobre a Danki Code</h1>
                </header>
                <p>
                  A Danki Code é uma empresa de desenvolvimento web que também
                  atua na área de cursos online voltados ao assunto.
                  Sediada em Florianópolis, desenvolve soluções inovadoras para
                  empresas formando profissionais capacitados e completos
                  para o mercado de Tecnologia.
                </p>
                <div>
                  <a
                    href="https://www.instagram.com/dankicodeoficial/"
                    rel={'noreferrer'}
                    target={'_blank'}
                    title={'Instagram Danki Code Oficial'}
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href="https://www.youtube.com/dankicode"
                    rel={'noreferrer'}
                    target={'_blank'}
                    title={'Youtube Danki Code Oficial'}
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    href="https://www.facebook.com/dankicode"
                    rel={'noreferrer'}
                    target={'_blank'}
                    title={'Facebook Danki Code Oficial'}
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </div>
              </footer>
            </div>
          </section>
        </Content>
      </Background>
    </>
  )
}
