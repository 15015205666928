import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { api } from '../services/api'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastMultipleNotification, ToastNotification } from '../services/toast'
import { useAgent } from './agent'

const {
  REACT_APP_TOKEN_REQUEST,
  REACT_APP_STORAGE_USER,
  REACT_APP_STORAGE_TOKEN
} = process.env

interface UserProps {
  id: number
  first_name: string
  last_name: string
  full_name: string
  cover: string
  url_cover: string
  plan: string
  plan_beneficies: boolean
  access_limit: string
  created_at: string
  email: string
  email_verified_at: string | null
  last_login_at: string | null
  last_login_ip: string | null
  status: boolean
  wallet_id_partial: string | null
  notifications: number
}

interface SignProps {
  email: string
  password: string
}

interface UpdateUserProps {
  first_name: string
  last_name: string
  password?: string
  password_confirmation?: string
}

interface AuthContextData {
  user: UserProps
  loading: boolean
  loggued: boolean
  accountVerified: boolean
  accountActive: boolean
  refreshLoading: boolean | null

  sign({ email, password }: SignProps): Promise<void>

  signOut(): Promise<void>

  getUser(token: string, redirect?: boolean): Promise<void>

  updateUser(data: UpdateUserProps): Promise<void>
}

interface AuthProviderProps {
  children: ReactNode
}

const AuthContext = createContext({} as AuthContextData)

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [token, setToken] = useState('')
  const [refreshLoading, setRefreshLoading] = useState<null | boolean>(null)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [loggued, setLoggued] = useState(false)
  const [accountVerified, setAccountVerified] = useState(false)
  const [accountActive, setAccountActive] = useState(false)
  const { agentData } = useAgent()

  async function validateData(data: UserProps) {
    if (data.email_verified_at) {
      setAccountVerified(true)
    } else {
      ToastNotification({
        text: 'Sua conta requer atenção. Faça a verificação do seu e-mail para ter acesso ao conteúdo.',
        typeNotify: 'warning',
        autoClose: 10000
      })
    }
    if (data.status) {
      setAccountActive(true)
    } else {
      navigate('/invoices')
      ToastNotification({
        text: 'Sua conta requer atenção.',
        typeNotify: 'warning',
        autoClose: 10000
      })
    }
    setLoggued(true)
    setRefreshLoading(true)
  }

  async function updateUser(update: UpdateUserProps) {
    try {
      await api.patch(`/user/update`, update, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const { data } = await api.get(`/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setUser(data)
      localStorage.setItem(String(REACT_APP_STORAGE_USER), JSON.stringify(data))
      await validateData(data)
    } catch (e) {
      throw new Error('Falha ao atualizar os dados')
    }
  }

  async function getUser(token: string, redirect: boolean = true) {
    try {
      const { data } = await api.get(`/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setUser(data)
      localStorage.setItem(String(REACT_APP_STORAGE_USER), JSON.stringify(data))
      await validateData(data)

      if (redirect) {
        ToastNotification({
          text: `Bem vindo(a) ${data.first_name}. Bons estudos!`,
          typeNotify: 'success'
        })
        // @ts-ignore
        const origin = location.state?.from?.pathname || '/'
        navigate(origin)
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({ text: data.message, typeNotify: 'warning' })
            }
          }
        }
      }
      throw new Error(
        'Falha ao fazer o acesso a sua conta, caso persista entre em contato com o suporte.'
      )
    }
  }

  async function sign({ email, password }: SignProps) {
    try {
      setLoading(true)
      const { data } = await api.post(
        `/login`,
        {
          email,
          password,
          device_name: agentData.userAgent,
          ip: agentData.IPv4
        },
        {
          headers: {
            Authorization: `${REACT_APP_TOKEN_REQUEST}`
          }
        }
      )
      setToken(data.access_token)
      localStorage.setItem(String(REACT_APP_STORAGE_TOKEN), data.access_token)
      await getUser(data.access_token)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({ text: data.message, typeNotify: 'warning' })
            }
          }
        }
      }
      throw new Error('Error request')
    }
  }

  async function signOut() {
    setLoading(true)
    localStorage.removeItem(String(REACT_APP_STORAGE_TOKEN))
    localStorage.removeItem(String(REACT_APP_STORAGE_USER))
    setUser({} as UserProps)
    setLoggued(false)
    try {
      await api.delete(`/logout`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setLoading(false)
      ToastNotification({ text: 'Até Logo!', typeNotify: 'info' })
      navigate('/sign')
    } catch (e) {
      setLoading(false)
      navigate('/sign')
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({
                text: 'Não autenticado, faça o login.',
                typeNotify: 'info'
              })
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    const dankiclub_token = localStorage.getItem(
      String(REACT_APP_STORAGE_TOKEN)
    )

    if (dankiclub_token && !token) {
      setToken(dankiclub_token)
    }

    const dankiclub_user = localStorage.getItem(String(REACT_APP_STORAGE_USER))
    if (dankiclub_user && !user.id) {
      const storageUser = JSON.parse(dankiclub_user)
      validateData(storageUser).then(() => setUser(storageUser))
      getUser(String(dankiclub_token), false).then(() => setLoading(false))
    }

    if (loading) {
      setLoading(false)
      setRefreshLoading(null)
    }

    // if (!dankiclub_token && !dankiclub_user) {
    // navigate('/sign')
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        loggued,
        accountVerified,
        accountActive,
        refreshLoading,
        sign,
        signOut,
        getUser,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }
