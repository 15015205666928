import React from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Button } from '../../components/Form/Button'
import { useNavigate } from 'react-router-dom'

export function NotFound() {
  const navigate = useNavigate()

  function handleHome() {
    navigate('/')
  }

  return (
    <>

      <Background>
        <h1>Página não encontrada</h1>
        <Content>
          <div className="background-transparency">
            <h2>404!</h2>
            <section>
              <h3>Ops! Página não encontrada</h3>
              <h3>A página que você tentou acessar não existe.</h3>
              {/*<p>Tente algum dos itens abaixo.</p>*/}
              <Button title={'Home'} onClick={handleHome} />
            </section>
          </div>
        </Content>
      </Background>
    </>
  )
}
