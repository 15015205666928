import React, { useEffect, useState } from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import { useAuth } from '../../hooks/auth'
import { api } from '../../services/api'
import { INotificationDataProps } from '../../Interfaces/NotificationProps'
import { Loading } from '../../components/Loading'
import { NavLink } from 'react-router-dom'

const { REACT_APP_STORAGE_TOKEN } = process.env

export function Notifications() {
  const [notifications, setNotifications] = useState(
    {} as INotificationDataProps[]
  )
  const [loading, setLoading] = useState(true)
  const { signOut, getUser } = useAuth()

  async function checkItem(id: number) {
    try {
      setLoading(true)
      const token = localStorage.getItem(String(REACT_APP_STORAGE_TOKEN))
      const { data } = await api.post(
        `/notifications/mark-read`,
        {
          notification_id: id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      getUser(String(token), false).then(() =>
        setNotifications(
          notifications.map((item) =>
            item.id === id ? { ...item, viewer: true } : item
          )
        )
      )

      setLoading(false)
      return ToastNotification({
        text: data.message,
        typeNotify: 'success'
      })
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function checkAllItems() {
    try {
      setLoading(true)
      const token = localStorage.getItem(String(REACT_APP_STORAGE_TOKEN))
      const { data } = await api.post(
        `/notifications/mark-read`,
        {
          all_mark: true
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      getUser(String(token), false).then(() => getNotifications())
      setLoading(false)
      return ToastNotification({
        text: data.message,
        typeNotify: 'success'
      })
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  async function getNotifications() {
    try {
      const { data } = await api.get(`/notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            String(REACT_APP_STORAGE_TOKEN)
          )}`
        }
      })
      setNotifications(data.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
        // @ts-ignore
        if (e.response.status === 401) {
          signOut().then()
        }
      }
    }
  }

  useEffect(() => {
    getNotifications().then()
  }, [])

  return (
    <>
      <Loading isLoading={loading} />
      <Helmet>
        <meta
          name="description"
          content="Confira sua notificações de mensagens, videos, faturas entre outros..."
        />
        <title>Notificações Danki Club</title>
      </Helmet>
      <Background>
        <h1>Notificações</h1>
        <Content>
          <section className={'background-transparency'}>
            <h2>Lista de notificações</h2>

            {notifications.length > 0 && (
              <div className="check-all">
                <button type={'button'} onClick={checkAllItems}>
                  <FontAwesomeIcon icon={faCheckDouble} />
                  Marcar todos como vistos
                </button>
              </div>
            )}

            <article>
              {notifications.length > 0 &&
                notifications.map((notification) => (
                  <div className="list" key={notification.id}>
                    <div className="item">
                      <FontAwesomeIcon icon={faBell} />
                      <p>
                        <small>{notification.type_notify}:</small>{' '}
                        <NavLink to={notification.url_notify}>
                          {notification.title}
                        </NavLink>
                      </p>
                      <small>{notification.extense_date}</small>
                    </div>
                    <button
                      disabled={notification.viewer}
                      type={'button'}
                      onClick={() => checkItem(notification.id)}
                      title={'Marcar notificação como vista'}
                    >
                      <FontAwesomeIcon icon={faCheckDouble} />{' '}
                      {notification.viewer ? 'Visto' : 'Não visto'}
                    </button>
                  </div>
                ))}
            </article>
          </section>
        </Content>
      </Background>
    </>
  )
}
