import styled from "styled-components";

export const Content = styled.div`
  section {
    display: grid;
    place-items: center;
    margin: 20px 0;

  }
`;
