import React from 'react'
import { Container } from './styles'
import urlSlug from 'url-slug'
import { NavLink } from 'react-router-dom'

interface IVideoCardProps {
  title: string
  video_id: number
  itemId: string
  cover: string
  large: boolean
  cover_origin: string | null
}

export function VideoCardSearch({
  title,
  video_id,
  cover_origin,
  cover,
  large
}: IVideoCardProps) {
  return (
    <Container tabIndex={0} backgroundImg={cover} largeTemplate={large}>
      <NavLink to={`/player/${video_id}/${urlSlug(title)}`}>
        <div className="card">{!cover_origin && <h2>{title}</h2>}</div>
      </NavLink>
    </Container>
  )
}
