import styled from 'styled-components'
import backgroundImg from '../../assets/images/bg.jpeg'

export const Container = styled.div`
  background-image: url('${backgroundImg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(70px);
  padding-bottom: 60px;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .laucher-at {
    position: relative;
    width: 100%;

    .vd-crop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      overflow: hidden;
      max-height: 667px;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(64, 7, 100, 0.1) 30%
      );
      position: relative;

      .volume-control {
        position: absolute;
        right: 50px;
        bottom: 200px;
        cursor: pointer;
        background: none;
        border: none;
        z-index: 2;

        @media (max-width: 480px) {
          right: 20px;
          bottom: 80px;
        }

        svg {
          color: var(--white);
          font-size: 2rem;
          transition: 0.2s;

          :hover {
            transition: 0.2s;
            color: var(--white-400);
          }
        }
      }

      .vd-container {
        background-color: var(--black);
        position: relative;
        overflow: hidden;
        z-index: -1;
      }

      .vd-container video,
      .vd-container object,
      .vd-container embed {
        aspect-ratio: 16 / 9;
        width: 100%;
      }
    }

    .updates {
      position: absolute;
      bottom: 0;
      z-index: 1;
      height: 160px;
      width: 100%;

      > div {
        bottom: -10px;
        width: 100%;
      }

      @media (max-width: 480px) {
        height: 10px;
        > div {
          bottom: 80px;
        }
      }

      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1) 40%,
        rgba(64, 7, 100, 0) 100%
      );
    }
  }

  .continue {
    width: 100%;
    position: relative;
    margin-top: 210px;
    margin-bottom: 2.5rem;

    @media (max-width: 480px) {
      margin-top: 140px;
      margin-bottom: 0;
    }
  }

  .highs-tags {
    padding: 0 20px;

    h2 {
      padding: 20px;
      font-size: 1rem;
      text-shadow: var(--black) 1px 1px 2px;
    }
  }

  .highs {
    position: relative;
  }
`
