import React, { InputHTMLAttributes } from 'react'
import { Container } from './styles'
import { Input } from '../Input'
import { Control, Controller } from 'react-hook-form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  control: Control
  name: string
  error: string
  securityEntry?: boolean
  type: string
}

export function InputForm({
  control,
  name,
  type,
  error,
  securityEntry = false,
  ...rest
}: Props) {
  return (
    <Container>
      <Controller
        control={control}
        render={({ field: { onChange } }) => (
          <Input
            type={type}
            securityEntry={securityEntry}
            onChange={onChange}
            active={!!error}
            {...rest}
          />
        )}
        name={name}
      />
      {error && <span>{error}</span>}
    </Container>
  )
}
