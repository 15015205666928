import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 100%;

  header {
    margin-bottom: 10px;
    padding: 0 20px;

    > h1 {
      font-size: 1.2rem;
      text-shadow: var(--black) 1px 1px 2px;
    }
  }

  :hover {
    button {
      transition: opacity 0.2s ease-in;
      opacity: 1;
    }
  }
  > div {
    position: relative;
    height: 100%;

    > h2 {
      background-color: var(--black-rgba);
      text-align: right;
      font-size: .85rem;
      font-weight: 900;
      padding: 10px 20px;
      margin: 0 20px;
      text-shadow: var(--black) 1px 1px 2px;
      text-transform: uppercase;
      
      @media(max-width: 768px){
        margin: 0;
      }
    }
  }
  
  
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px 40px;
  }

  .react-horizontal-scrolling-menu--wrapper {
    position: relative;
  }
`
