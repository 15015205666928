import React, { ReactNode } from 'react'
import { Container } from './styles'

interface IBackgroundProps {
  children: ReactNode
}

export function Background({ children }: IBackgroundProps) {
  return <Container>{children}</Container>
}
