import React, { FormEvent, useState } from 'react'
import { Container, Button } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { ToastNotification } from '../../services/toast'

interface IProps {
  onPress: (term: string) => void
    placeholder: string
}

export function SearchBar({ onPress, placeholder }: IProps) {
  const [search, setSearch] = useState('')

  function handleSearch(form: FormEvent) {
    form.preventDefault()
    if (search.length < 3) {
      return ToastNotification({
        text: 'Seja mais específico, somente com isto não consigo pesquisar para você.',
        typeNotify: 'info'
      })
    }
    onPress(search)
    setSearch('')
  }

  return (
    <Container autoComplete={'off'}>
      <input
        type="text"
        name={'search'}
        autoComplete={'off'}
        placeholder={placeholder.length ? placeholder : 'React, Html, Css...'}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button onClick={handleSearch}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </Container>
  )
}

export function SearchButton() {
  const navigate = useNavigate()
  const { user } = useAuth()
  return (
    user && (
      <>
        <Button onClick={() => navigate('search')}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </>
    )
  )
}
