import React, { ChangeEvent, useState } from 'react'
import { Container } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStartOutline } from '@fortawesome/free-regular-svg-icons'
import { api } from '../../services/api'
import LoadingSvg from '../../assets/images/loading.svg'
import { ToastNotification } from '../../services/toast'
import { InputList } from './InputList'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { IListProps } from '../../Interfaces/FavoriteVideoListProps'

const { REACT_APP_STORAGE_TOKEN } = process.env

interface Props {
  video: number
  isFavorite: boolean
}

export function FavoriteAction({ video, isFavorite }: Props) {
  const [list, setList] = useState({} as IListProps[])
  const [loading, setLoading] = useState(false)
  const [openList, setOpenList] = useState(false)
  const [star, setStar] = useState(isFavorite)

  function verifyFavorite(items: IListProps[]) {
    if (!items.find((item) => item.is_favorite)) {
      setStar(false)
    } else {
      setStar(true)
    }
  }

  function pushItemList(data: IListProps) {
    setList((old) => [data, ...old])
  }

  async function getList() {
    setLoading(true)
    setOpenList(!openList)
    if (!openList) {
      const { data } = await api.get(`/favorite?video=${video}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            String(REACT_APP_STORAGE_TOKEN)
          )}`
        }
      })
      setList(data.data)
    }
    setLoading(false)
  }

  async function setFavorite(event: ChangeEvent<HTMLInputElement>, id: number) {
    try {
      event.target.disabled = true
      const check = event.target.checked
      const { data } = await api.post(
        `/favorite/register-video/${video}`,
        {
          check,
          list_id: id
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      event.target.disabled = false
      const listUpdate = list.map((item) =>
        item.id === id ? { ...item, is_favorite: check } : { ...item }
      )
      setList(listUpdate)
      verifyFavorite(listUpdate)
      return ToastNotification({
        text: data.message,
        typeNotify: check ? 'success' : 'info'
      })
    } catch (e) {
      return ToastNotification({
        text: 'Não foi possível adicionar o vídeo na lista',
        typeNotify: 'warning'
      })
    }
  }

  return (
    <Container>
      <button type={'button'} onClick={getList}>
        <FontAwesomeIcon icon={star ? faStar : faStartOutline} /> Favoritos
      </button>
      <div className={openList ? 'show' : 'hide'}>
        <InputList handleUpdateList={pushItemList} />
        {loading ? (
          <img src={LoadingSvg} alt="Carregando..." />
        ) : (
          <ul>
            {list.length > 0 &&
              list.map((item) => (
                <li key={item.id}>
                  <input
                    type="checkbox"
                    id={`list_${item.id}`}
                    name={'checkbox'}
                    value={String(!item.is_favorite)}
                    defaultChecked={item.is_favorite}
                    onChange={(event) => setFavorite(event, item.id)}
                  />
                  <label htmlFor={`list_${item.id}`}>{item.name}</label>
                </li>
              ))}
          </ul>
        )}
      </div>
    </Container>
  )
}
