import styled from 'styled-components'

interface ButtonProps {
  color?: string
}

export const Container = styled.button<ButtonProps>`
  padding: 12px 40px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  color: var(--white);
  font-weight: bold;
  transition: 0.2s;

  :hover {
    transition: 0.2s;
    background-color: var(--purple-100);
  }
  margin-bottom: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => (color ? color : `var(--pink)`)};
  font-size: 0.95rem;

  :hover {
    transition: 0.2s;
    background-color: ${({ color }) => (color ? `var(--black-rgba)` : color)};
  }
`
