import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;

  .background-transparency {
    display: flex;
    flex-direction: column;
    height: 100%;
    place-content: center;
    place-items: center;
    width: 100%;
    background-color: var(--purple-200-rgba);
    padding: 20px;

    h2 {
      text-align: center;
      font-size: 3rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    section {
      display: grid;
      place-items: center;
      place-content: center;
      margin: 20px 0;
    }
  }
`
