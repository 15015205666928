import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;

  .background-transparency {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--purple-200-rgba);
    padding: 20px;

    form {
      border-radius: 4px;
      width: 100%;
      display: grid;
      grid-template-columns: 250px 1fr;
      grid-column-gap: 1rem;
      
      @media(max-width: 720px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
      }
      
      h2 {
        width: 100%;
        text-align: center;
        grid-column: 3 span;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--purple);
        
        @media(max-width: 720px) {
          grid-column: 1 span;
        }
        
        span {
          display: block;
          font-size: 1rem;
          margin-top: 10px;
        }
      }
      .avatar {
        width: 100%;
        display: grid;
        place-items: center;
        grid-row-gap: 1rem;

        border: 4px solid var(--purple-300-rgba);
        background-color: var(--purple-200);
        border-radius: 4px;
        padding: 30px 10px;

        @media(max-width: 720px) {
          background-color: transparent;
          border: none;
        }
        
        button {
          width: 100%;
          padding: 10px ;
          font-size: .75rem;
          background-color: var(--purple);
        }
        
        .picture {
          width: 180px;
          height: 180px;
          border-radius: 90px;
          overflow: hidden;
          box-shadow: 0 0 2rem var(--black-rgba);
          
          img {
            width: 180px;
            height: auto;
          }
        }
        
        .vigence {
          width: 100%;
          background-color: var(--black-rgba);
          padding: 10px;
          text-align: center;
          span {
            margin-top: 10px;
            display: block;
            font-weight: 900;
            text-transform: uppercase;
          }
        }
      }
      .row {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-template-rows: auto;
        grid-column-gap: 1rem;
        place-content: start;
        grid-row-gap: 1.2rem;

        > div {
          
          label {
            display: block;
            margin-bottom: 5px;

            font-size: 0.75rem;
            text-transform: uppercase;
          }
          
          button {
            padding: 12px 40px;
            @media(max-width: 480px) {
              width: 100%;
            }
          }
        }
      }
    }
    
    .invoices {
      width: 100%;
      
      button {
        width: 100%;
        max-width: 250px;
        font-size: .90rem;
        
        @media(max-width: 480px) {
          max-width: 100%;
        }
      }
    }
  }
`
