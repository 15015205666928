import React from 'react'
import {Container} from './styles'
import LogoDankiCodeSvg from '../../assets/images/logo-danki-code.svg'

export function Footer() {
    return (
        <Container>
            <div>
                <img src={LogoDankiCodeSvg} alt="Danki Code"/>
                <p>
                    É proibida a reprodução ou distribuição do conteúdo publicado aqui.
                </p>
            </div>
            <div>
                <p>© Danki Code - Todos os direitos reservados</p>
                <p>CNPJ: 25.162.666/0001-77</p>
                <p>Plataforma Desenvolvida com ❤️ por Danki Code.</p>
            </div>
        </Container>
    )
}
