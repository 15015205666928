import React, { useState } from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../services/api'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import axios from 'axios'
import { Form } from '../../components/Form'
import { InputForm } from '../../components/Form/InputForm'
import { Button } from '../../components/Form/Button'

const { REACT_APP_TOKEN_REQUEST, REACT_APP_STORAGE_TOKEN } = process.env

export function EmailVerify() {
  const params = useParams()
  const { id, hash } = params
  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const { loggued, accountVerified, getUser } = useAuth()
  const schema = Yup.object().shape({
    passwordConfirm: Yup.string()
      .required('A confirmação de senha é obrigatória.')
      .oneOf([Yup.ref('password')], 'As Senhas não são iguais.'),
    password: Yup.string()
      .min(8, 'Digite pelo menos 8 carácteres')
      .required('A senha é obrigatória.'),
    lastName: Yup.string().required('Sobrenome é obrigatório.'),
    firstName: Yup.string().required('Nome é obrigatório.'),
    email: Yup.string()
      .required('O e-mail é obrigatório.')
      .email('E-mail inválido')
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  async function handleVerification(form: any) {
    try {
      if (searchParams.get('email')?.replace(' ', '+') !== form.email) {
        return ToastNotification({
          text: 'O e-mail de cadastro é diferente do informado',
          typeNotify: 'error',
          autoClose: 10000
        })
      }
      setLoading(true)
      const { data } = await api.post(
        `/verify-account/${id}/${hash}?expires=${searchParams.get(
          'expires'
        )}&signature=${searchParams.get('signature')}`,
        {
          email: form.email,
          first_name: form.firstName,
          last_name: form.lastName,
          password: form.password,
          password_confirmation: form.password
        },
        {
          headers: {
            Authorization: `${REACT_APP_TOKEN_REQUEST}`
          }
        }
      )
      ToastNotification({
        text: 'Dados confirmados e conta atualizada com sucesso.',
        typeNotify: 'success'
      })
      localStorage.setItem(String(REACT_APP_STORAGE_TOKEN), data.access_token)
      await getUser(data.access_token)
      await reset()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (e instanceof Yup.ValidationError) {
        ToastNotification({ text: e.message, typeNotify: 'warning' })
      }
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({ text: data.message, typeNotify: 'warning' })
            }
          }
        }
      }
    }
  }

  if (loggued && accountVerified) {
    return <Navigate to={'/'} replace />
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Confirmação de conta no Danki Club</title>
      </Helmet>
      <Background>
        <Content>
          <Form
            title={'Concluir dados de cadastro'}
            handleSubmit={handleSubmit((data) => handleVerification(data))}
          >
            <h2>
              Para confirmar sua conta, complete ou verifique os dados abaixo.
            </h2>
            <div>
              <label htmlFor="email">E-mail da sua compra na Hotmart</label>
              <InputForm
                control={control}
                name={'email'}
                type="email"
                id={'email'}
                placeholder={'E-mail'}
                error={errors.email && errors.email.message}
              />
            </div>
            <div>
              <label htmlFor="firstName">Nome</label>
              <InputForm
                name={'firstName'}
                control={control}
                type="text"
                id={'firstName'}
                placeholder={'Primeiro nome'}
                error={errors.firstName && errors.firstName.message}
              />
            </div>
            <div>
              <label htmlFor="lastName">Sobrenome</label>
              <InputForm
                name={'lastName'}
                control={control}
                type="text"
                id={'lastName'}
                placeholder={'Sobrenome'}
                error={errors.lastName && errors.lastName.message}
              />
            </div>
            <div>
              <label htmlFor="password">Senha</label>
              <InputForm
                name={'password'}
                control={control}
                type="password"
                id={'password'}
                placeholder={'Senha'}
                securityEntry={true}
                error={errors.password && errors.password.message}
              />
            </div>
            <div>
              <label htmlFor="passwordConfirm">Confirme a Senha</label>
              <InputForm
                name={'passwordConfirm'}
                control={control}
                type="password"
                id={'passwordConfirm'}
                placeholder={'Confirme a Senha'}
                securityEntry={true}
                error={errors.passwordConfirm && errors.passwordConfirm.message}
              />
            </div>
            <div>
              <Button title={'Confirmar'} disabled={loading} type={'submit'} />
            </div>
          </Form>
        </Content>
      </Background>
    </>
  )
}
