import styled from 'styled-components'
import backgroundImg from '../../assets/images/bg.jpeg'

export const Container = styled.div`
  background-image: url('${backgroundImg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(70px);
  height: 100%;
  padding: 20px;

  > h1 {
    padding-left: 70px;
    text-transform: capitalize;

    @media (max-width: 760px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

`
