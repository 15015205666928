import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;

  .background-transparency {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    height: 100%;
    width: 100%;
    background-color: var(--purple-200-rgba);
    padding: 20px;

    > h2 {
      width: 100%;
      text-align: center;
      grid-column: 3 span;

      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--purple);
    }

    .check-all {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    button {
      background: var(--purple-100);
      border: none;
      border-radius: 6px;
      padding: 6px 12px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--white);
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 800;
      transition: all 0.2s;
      display: flex;

      svg {
        margin: 5px;
      }

      &:hover {
        transition: all 0.2s;
        background: var(--pink);
      }
    }

    .list {
      background: var(--purple-400);
      border-bottom: 1px solid var(--purple-300);
      padding: 20px 25px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 1rem;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      transition: all 0.2s;

      &:hover {
        transition: all 0.2s;
        background: var(--black-rgba);
      }

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: none;
      }

      .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 1rem;

        svg {
          font-size: 1.2rem;
          color: var(--purple);
        }

        p {
          a {
            @media (max-width: 720px) {
              display: block;
            }
            font-weight: 600;
            color: var(--purple);

            &:hover {
              color: var(--pink);
            }
          }
        }

        small {
          font-weight: 600;
        }
      }
    }
  }
`
