import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;

  .background-transparency {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--purple-200-rgba);
    padding: 20px;

    > h2 {
      width: 100%;
      text-align: center;
      grid-column: 3 span;

      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--purple);
    }

    > div {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      justify-content: space-around;
      grid-gap: 1rem;
      
      @media(max-width: 768px){
        grid-template-columns: 1fr;
      }

      h1 {
        margin-bottom: 10px;
      }

      article {
        padding: 30px 20px;
        width: 100%;
        background-color: var(--black-rgba);

        h1 {
          margin-bottom: 10px;
          font-size: 1rem;
          text-transform: uppercase;
        }

        p {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          
          strong {
            margin-right: 5px;
            color: var(--white-400);
          }

          svg {
            margin-right: 10px;
            color: var(--pink);
          }
        }
      }

      footer {
        padding: 30px 20px;
        width: 100%;
        background-color: var(--black-rgba);

        h1 {
          margin-bottom: 10px;
          font-size: 1rem;
          text-transform: uppercase;
        }

        p {
          line-height: 24px;
        }

        > div {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          grid-gap: 1rem;
          justify-content: center;
          align-items: center;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 25px;
            padding: 10px;
            background-color: var(--pink);

            :hover {
              background-color: var(--purple-200);
              transform: scale(1.1);
            }
          }
        }
      }
      
    }
  }
`
