import { useEffect, useState } from 'react'
import axios from 'axios'

interface UserAgentProps {
  userAgent: string
  IPv4?: string
  city?: string
  country_code?: string
  country_name?: string
  latitude?: number
  longitude?: number
  postal?: string
  state?: string
}

function useAgent() {
  const [agentData, setAgentData] = useState({} as UserAgentProps)

  useEffect(() => {
    const agent = window.navigator.userAgent
    ;(async () => {
      try {
        const { data } = await axios.get(`https://geolocation-db.com/json/`)
        setAgentData({
          userAgent: agent,
          ...data
        })
      } catch (e) {
        setAgentData({
          userAgent: agent,
          IPv4: 'Não autorizado'
        })
      }
    })()
  }, [])

  return { agentData }
}

export { useAgent }
