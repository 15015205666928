import React, { useEffect, useState } from 'react'
import { Content, SearchBox } from './styles'
import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { IVideoProps } from '../../Interfaces/VideoProps'
import { api } from '../../services/api'
import { ToastNotification } from '../../services/toast'
import { Loading } from '../../components/Loading'
import { VideoCardSearch } from '../../components/VideoCardSearch'
import { SearchBar } from '../../components/SearchBar'

const { REACT_APP_STORAGE_TOKEN } = process.env

export function Search() {
  const [loading, setLoading] = useState(false)
  const [videos, setVideos] = useState({} as IVideoProps)
  const [search, setSearch] = useState('')

  async function handleSearch(term: string) {
    try {
      setVideos({} as IVideoProps)
      setSearch(term)
      setLoading(true)
      const { data } = await api.get(
        `/videos?page=1&search=${encodeURI(String(term))}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setVideos(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      return ToastNotification({
        text: 'Não foi possível obter nenhum resultado em sua pesquisa.',
        typeNotify: 'info'
      })
    }
  }

  useEffect(() => {}, [])

  return (
    <>
      <Loading isLoading={loading} />
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Pesquisa Danki Club</title>
      </Helmet>
      <Background>
        <SearchBox>
          <h1>Pesquisa</h1>
          <SearchBar onPress={handleSearch} placeholder={search} />
        </SearchBox>
        <Content>
          <div className="background-transparency">
            {Object.keys(videos).length > 0 ? (videos.data.length > 0 ? (
              videos.data.map((video, id) => (
                <VideoCardSearch
                  key={id}
                  title={video.title}
                  video_id={video.id}
                  itemId={String(id)}
                  cover={video.cover_url}
                  large
                  cover_origin={video.cover}
                />
              ))
            ) : (
              <p className={'not-found'}>
                Nenhum resultado para <span>{search}</span>
              </p>
            )) : <p className={'not-found'}>Digite sua pesquisa.</p>}
          </div>
        </Content>
      </Background>
    </>
  )
}
