import styled from 'styled-components'
import { css } from 'styled-components'
import { InputHTMLAttributes } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  active: boolean
  color?: string
}

export const InputContainer = styled.div<Props>`
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  border: 2px solid var(--purple-200);
  border-radius: 4px;
  margin-bottom: 5px;
  overflow: hidden;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      border-width: 3px;
      border-color: var(--pink);
    `}
`

export const FormInput = styled.input<Props>`
  font-size: 0.85rem;
  color: var(--purple-300);
  padding: 10px 14px;
  border: none;
  width: 100%;
  position: relative;
  outline: none;

  ::placeholder {
    color: var(--white-400);
    text-transform: uppercase;
  }
`

export const ToggleShowPassButton = styled.strong`
  width: 30px;
  right: 10px;
  position: absolute;
  z-index: 1;
  padding: 10px;

  svg {
    color: var(--purple-200);
  }
`
