import React from 'react'
import { Content } from './styles'
import { Background } from '../../components/Background'
import { useAuth } from '../../hooks/auth'

import { Link, Navigate } from 'react-router-dom'
import { InputForm } from '../../components/Form/InputForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Button } from '../../components/Form/Button'
import {Form} from "../../components/Form";
import {ToastNotification} from "../../services/toast";
import {Loading} from "../../components/Loading";
import {Helmet} from "react-helmet";

interface FormDataProps {
  email: string
  password: string
}

export function Sign() {
  const { sign, loading, loggued } = useAuth()

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Digite pelo menos 8 carácteres')
      .required('A senha é obrigatória.'),
    email: Yup.string()
      .required('O e-mail é obrigatório.')
      .email('E-mail inválido')
  })
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  async function handleSign(form: any) {
    const { email, password }: FormDataProps = form
    try {
      await sign({ email, password })
      reset()
    } catch (e) {
      ToastNotification({
        text: 'Falha ao obter seu token de acesso, caso persista entre em contato com o suporte.',
        typeNotify: 'error'
      })
      if (e instanceof Yup.ValidationError) {
        console.log(e)
      }
    }
  }

  if (loggued) {
    return <Navigate to={'/'} replace />
  }

  return (
<>
  <Loading isLoading={loading} />
  <Helmet>
    <meta name="description" content="Bem vindo(a) ao Danki Club seu ponto direto com o aprendizado!" />
    <title>Login Danki Club</title>
  </Helmet>
  <Background>
    <Content>
      <Form title={'Acesse sua conta'} handleSubmit={handleSubmit((data) => handleSign(data))}>
        <div>
          <label htmlFor="email">E-mail</label>
          <InputForm
              control={control}
              name={'email'}
              type="email"
              id={'email'}
              placeholder={'E-mail'}
              error={errors.email && errors.email.message}
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <InputForm
              name={'password'}
              control={control}
              type="password"
              id={'password'}
              placeholder={'Senha'}
              securityEntry={true}
              error={errors.password && errors.password.message}
          />
        </div>
        <p>
          <Link
              to="/email/password-email"
              title={'Recuperação de senha por e-mail'}
          >
            Esqueceu a senha?
          </Link>
          <a
              href="https://dankiclub.com"
              target={'_blank'}
              rel={"noreferrer"}
              title={'Escolha um plano e Cadastre-se no Danki Club'}
          >
            Cadastre-se
          </a>
        </p>
        <div>
          <Button title={'Entrar'} disabled={loading} type={'submit'} />
        </div>
      </Form>
    </Content>
  </Background>
</>
  )
}
