import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  place-content: center;
  place-items: center;
  margin: 20px 0;
  height: 100%;
 
`
