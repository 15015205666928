import React from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import { Home } from './pages/Home'
import { Bookmarker } from './pages/Bookmarker'
import { History } from './pages/History'
import { Notifications } from './pages/Notifications'
import { Settings } from './pages/Settings'
import { Search } from './pages/Search'
import { Sign } from './pages/Sign'
import { NotFound } from './pages/NotFound'
import { PasswordReset } from './pages/PasswordReset'
import { PasswordEmail } from './pages/PasswordEmail'
import { EmailVerification } from './pages/EmailVerification'
import { EmailVerify } from './pages/EmailVerify'
import { ProtectedRoute } from './middlewares'
import { Invoices } from './pages/Invoices'
import { Support } from './pages/Support'
import { PlayerVideo } from './pages/PlayerVideo'

export function Routes() {
  return (
    <Switch>
      <Route
        index
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path={'book-marker'}
        element={
          <ProtectedRoute>
            <Bookmarker />
          </ProtectedRoute>
        }
      />
      <Route
        path={'history'}
        element={
          <ProtectedRoute>
            <History />
          </ProtectedRoute>
        }
      />
      <Route
        path={'notifications'}
        element={
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        }
      />
      <Route
        path={'search'}
        element={
          <ProtectedRoute>
            <Search />
          </ProtectedRoute>
        }
      />
      <Route
        path={'player/:video_id/:slug'}
        element={
          <ProtectedRoute>
            <PlayerVideo />
          </ProtectedRoute>
        }
      />
      <Route path={'support'} element={<Support />} />

      <Route
        path={'settings'}
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path={'invoices'}
        element={
          <ProtectedRoute>
            <Invoices />
          </ProtectedRoute>
        }
      />

      <Route path={'email'}>
        <Route path={'verify/:id/:hash'} element={<EmailVerify />} />
        <Route
          path={'verification-notification'}
          element={<EmailVerification />}
        />
        <Route path={'password-email'} element={<PasswordEmail />} />
        <Route path={'password-reset/:hash'} element={<PasswordReset />} />
      </Route>
      <Route path={'sign'} element={<Sign />} />
      <Route path="*" element={<NotFound />} />
    </Switch>
  )
}
