import styled from "styled-components";

interface IButtonProps {
    disabled: boolean
}

export const Button = styled.button<IButtonProps>`
  width: 40px;
  border: none;
  outline: none;
  background: transparent;
  z-index: 2;

  opacity: 0.5;
  display: ${({ disabled }) => (disabled ? 'none' : 'grid')};
  user-select: none;
  position: absolute;

  place-items: center;
  height: 100%;
  transition: opacity 0.2s ease-in;

  :first-child {
    left: 0;
  }

  :last-child {
    right: 0;
  }

  svg {
    transition: all 0.2s ease-out;
    color: var(--white);
    font-size: 2rem;
  }

  :hover {
    svg {
      transition: all 0.2s ease-out;
      font-size: 2.4rem;
    }
  }
`
