import React, { ReactNode } from 'react'
import { Container } from './styles'
import Logo from '../../assets/images/danki-club.svg'

interface FormProps {
  children: ReactNode
  title: string
  handleSubmit?: () => void
}

export function Form({ title, handleSubmit, children }: FormProps) {
  return (
    <Container onSubmit={handleSubmit}>
      <img src={Logo} alt="Danki Club" />
      <h1>{title}</h1>
      {children}
    </Container>
  )
}
