import React, { useEffect, useState } from 'react'
import { Content } from './styles'

import { Background } from '../../components/Background'
import { useAuth } from '../../hooks/auth'
import { useForm } from 'react-hook-form'
import { Form } from '../../components/Form'
import { InputForm } from '../../components/Form/InputForm'
import { Button } from '../../components/Form/Button'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../services/api'
import axios from 'axios'

const { REACT_APP_TOKEN_REQUEST } = process.env

export function PasswordReset() {
  const { loggued } = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const { hash } = params
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const schema = Yup.object().shape({
    passwordConfirm: Yup.string()
      .required('A confirmação de senha é obrigatória.')
      .oneOf([Yup.ref('password')], 'As Senhas não são iguais.'),
    password: Yup.string()
      .min(8, 'Digite pelo menos 8 carácteres')
      .required('A senha é obrigatória.')
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  async function handleResetPassword(form: any) {
    try {
      setLoading(true)
      const { password, passwordConfirm } = form
      await api.post(
        `/password/reset/`,
        {
          email,
          password,
          password_confirmation: passwordConfirm,
          token: hash
        },
        {
          headers: {
            Authorization: `${REACT_APP_TOKEN_REQUEST}`
          }
        }
      )
      setLoading(false)
      await reset()
      ToastNotification({
        text: 'Senha alterada com sucesso, entre com seus dados de acesso.',
        typeNotify: 'success'
      })
      navigate('/sign')
    } catch (e) {
      setLoading(false)
      if (e instanceof Yup.ValidationError) {
        ToastNotification({ text: e.message, typeNotify: 'warning' })
      }
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              ToastNotification({ text: data.message, typeNotify: 'warning' })
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const { data } = await api.get(`/password/reset/${hash}`, {
          headers: {
            Authorization: `${REACT_APP_TOKEN_REQUEST}`
          }
        })
        if (!data.email) {
          throw new Error('Não foi possível validar este token')
        } else {
          setEmail(data.email)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        ToastNotification({
          text: 'Não foi possível validar este token, tente solicitar uma nova recuperação de senha.',
          typeNotify: 'warning',
          autoClose: 10000
        })
        navigate('/email/password-email')
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loggued) {
    return <Navigate to={'/'} replace />
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Recuperação de conta Danki Club</title>
      </Helmet>
      <Background>
        <Content>
          <Form
            title={'Alteração de senha'}
            handleSubmit={handleSubmit((data) => handleResetPassword(data))}
          >
            <div>
              <label htmlFor="email">E-mail</label>
              <InputForm
                control={control}
                name={'email'}
                type="email"
                id={'email'}
                value={email}
                placeholder={'E-mail'}
                disabled={true}
                error={errors.email && errors.email.message}
              />
            </div>
            <div>
              <label htmlFor="password">Nova Senha</label>
              <InputForm
                name={'password'}
                control={control}
                type="password"
                id={'password'}
                placeholder={'Senha'}
                securityEntry={true}
                error={errors.password && errors.password.message}
              />
            </div>
            <div>
              <label htmlFor="passwordConfirm">Confirme a nova Senha</label>
              <InputForm
                name={'passwordConfirm'}
                control={control}
                type="password"
                id={'passwordConfirm'}
                placeholder={'Confirme a nova Senha'}
                securityEntry={true}
                error={errors.passwordConfirm && errors.passwordConfirm.message}
              />
            </div>
            <p>
              <Link to="/sign" title={'Entrar com seus dados de acesso'}>
                Entrar
              </Link>
              <a
                href="https://dankiclub.com"
                target={'_blank'}
                rel={"noreferrer"}
                title={'Escolha um plano e Cadastre-se no Danki Club'}
              >
                Cadastre-se
              </a>
            </p>
            <div>
              <Button title={'Confirmar'} disabled={loading} type={'submit'} />
            </div>
          </Form>
        </Content>
      </Background>
    </>
  )
}
