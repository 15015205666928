import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;

  .background-transparency {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--purple-200-rgba);
    padding: 20px;

    > h2 {
      width: 100%;
      text-align: center;
      grid-column: 3 span;

      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--purple);
    }

    > div {
      display: grid;
      grid-template-columns: 260px 1fr;
      grid-column-gap: 2rem;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
      }

      nav {
        width: 100%;

        input {
          padding: 10px 15px;
        }

        ul {
          background-color: var(--black-rgba);
          display: grid;
          grid-row-gap: 0.5rem;
          padding: 20px;

          li {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            list-style: none;
            font-size: 1.1rem;
            justify-content: space-between;

            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--purple-100-rgba);

            :last-of-type {
              border-bottom: none;
              padding-bottom: 0;
            }

            span {
              svg {
                margin-right: 10px;
                color: var(--yellow-100);
              }

              :hover {
                color: var(--pink);
              }
            }

            svg {
              color: var(--red-400);

              :hover {
                color: var(--red);
              }
            }
          }

          li.active {
            span {
              > svg {
                color: var(--pink);
              }

              color: var(--pink);
            }
          }
        }
      }

      article {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-row-gap: 1rem;

        form {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          grid-gap: 1rem;
          margin-bottom: 20px;

          @media (max-width: 1170px) {
            display: grid;
            grid-template-columns: 1fr 1fr;

            label {
              grid-column: 2 span;
            }
          }

          label {
            background-color: var(--black-rgba);
            display: flex;
            height: 100%;
            align-items: center;
            padding: 8px 20px;

            font-size: 1rem;
            font-weight: 600;
            text-transform: uppercase;
          }

          input {
            font-size: 0.85rem;
            color: var(--purple-300);
            padding: 10px 14px;
            border: none;
            width: 100%;
            max-width: 420px;
            position: relative;
            outline: none;

            ::placeholder {
              color: var(--white-400);
              text-transform: uppercase;
            }
          }

          button {
            margin: 0;
            font-size: 0.7rem;
          }
        }

        h3 {
          display: grid;
          place-self: center;
        }

        .list-videos {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(auto, 420px));
          grid-gap: 1rem;
        }
      }
    }
  }
`
