import React from 'react'
import { Container } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faUserCircle } from '@fortawesome/free-solid-svg-icons'

interface Props {
  id: number
  full_name: string
  extense_date: string
  content: string
  customer_cover: string
}

export function List(data: Props) {
  return (
    <Container>
      <div className="picture">
        <img src={data.customer_cover} alt={data.full_name} />
      </div>
      <div className="text">
        <div className="user-coment">
          <div className="user">
            <FontAwesomeIcon icon={faUserCircle} />
            <p>{data.full_name}</p>
          </div>
          <div className="date">
            <FontAwesomeIcon icon={faClock} />
            <p>{data.extense_date}</p>
          </div>
        </div>
        <section
          dangerouslySetInnerHTML={{
            __html: data.content
          }}
        />
      </div>
    </Container>
  )
}
