import React from 'react'
import { Container } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faCogs,
  faHeadset,
  faHistory,
  faSearch,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'

import LogoDankiClubPng from '../../assets/images/danki-club.svg'
import MenuSvg from '../../assets/images/menu-icon.svg'

import {
  faBell,
  faPlayCircle,
  faStar
} from '@fortawesome/free-regular-svg-icons'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'

interface IMenuProps {
  active: boolean
  onActiveMenu: (active?: boolean) => void
}

export function Menu({ onActiveMenu, active }: IMenuProps) {
  const { signOut, loading, user, loggued, accountVerified } = useAuth()

  async function handleSignOut() {
    await signOut()
    onActiveMenu()
  }

  return (
    <Container onMouseLeave={() => onActiveMenu(false)}>
      {!active && (
        <button type={'button'} onClick={() => onActiveMenu(true)}>
          <img src={MenuSvg} alt="" />
        </button>
      )}

      <div className={active ? 'nav-container show' : 'nav-container hide'}>
        <div className="logo-container">
          <img src={LogoDankiClubPng} alt="DankiClub" />
          <button type={'button'} onClick={() => onActiveMenu(false)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>

        <div className="divisor" />

        <div className={'list'}>
          <h2>Menu</h2>
          <NavLink to="/">
            <div>
              <div className="tag-border" />
              <FontAwesomeIcon icon={faPlayCircle} />
              Início
            </div>
          </NavLink>
          <NavLink to="/search">
            <div>
              <div className="tag-border" />
              <FontAwesomeIcon icon={faSearch} />
              Pesquisar
            </div>
          </NavLink>
          <NavLink to="book-marker">
            <div>
              <div className="tag-border" />
              <FontAwesomeIcon icon={faStar} />
              Favoritos
            </div>
          </NavLink>
          <NavLink to="history">
            <div>
              <div className="tag-border" />
              <FontAwesomeIcon icon={faHistory} />
              Histórico
            </div>
          </NavLink>
        </div>

        {loggued && (
          <>
            <div className="divisor" />

            <div className="list">
              <h2>Minha Conta</h2>
              {accountVerified && (
                <>
                  <div className="profile">
                    <img src={user.url_cover} alt={user.first_name} />
                    <p>
                      {user.first_name} <strong>Danki {user.plan}</strong>
                    </p>
                  </div>
                  <NavLink to="notifications">
                    <div>
                      <div className="tag-border" />
                      <FontAwesomeIcon icon={faBell} />
                      Notificações
                    </div>
                    <span>{user.notifications}</span>
                  </NavLink>
                  <NavLink to="settings">
                    <div>
                      <div className="tag-border" />
                      <FontAwesomeIcon icon={faCogs} />
                      Configurações
                    </div>
                  </NavLink>
                </>
              )}
              <button
                type={'button'}
                onClick={handleSignOut}
                disabled={loading}
              >
                <div>
                  <div className="tag-border" />
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  Sair
                </div>
              </button>
            </div>
          </>
        )}

        <div className="divisor" />

        <div className="list">
          <NavLink to="support">
            <div>
              <div className="tag-border" />
              <FontAwesomeIcon icon={faHeadset} />
              Suporte
            </div>
          </NavLink>
        </div>
      </div>
    </Container>
  )
}
