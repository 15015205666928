import React, { useState } from 'react'
import { Content } from './styles'
import { Background } from '../../components/Background'
import { Helmet } from 'react-helmet'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { InputForm } from '../../components/Form/InputForm'
import { Button } from '../../components/Form/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ToastNotification } from '../../services/toast'
import { Loading } from '../../components/Loading'

interface IProps {
  firstName: string
  lastName: string
  password?: string
  passwordConfirm?: string
  walletId?: string
}

export function Settings() {
  const { loggued, user, updateUser } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const schema = Yup.object().shape({
    passwordConfirm: Yup.string()
      .trim()
      .nullable()
      .when('password', {
        is: (val: any) => {
          if (val === '') {
            clearErrors(['password', 'passwordConfirm'])
            return false
          }
          return typeof val !== 'undefined'
        },
        then: Yup.string()
          .required('A Confirmação senha é obrigatória')
          .oneOf([Yup.ref('password')], 'As Senhas não são iguais.')
          .min(8, 'Digite pelo menos 8 carácteres')
      }),
    password: Yup.string().trim().nullable(),
    lastName: Yup.string()
      .required('Sobrenome é obrigatório.')
      .default(user.last_name),
    walletId: Yup.string(),
    firstName: Yup.string()
      .required('Nome é obrigatório.')
      .default(user.first_name)
  })

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    resetField
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  })

  async function handleVerification(form: any) {
    try {
      setLoading(true)
      const {
        firstName,
        lastName,
        password,
        passwordConfirm,
        walletId
      }: IProps = form
      const update = {
        first_name: firstName,
        last_name: lastName
      }
      if (password !== '') {
        Object.assign(update, {
          password,
          password_confirmation: passwordConfirm
        })
      }
      if (walletId !== '') {
        Object.assign(update, {
          wallet_id: walletId
        })
      }
      await updateUser(update)
      resetField('walletId')
      setLoading(false)
      ToastNotification({
        text: 'Dados atualizados com sucesso.',
        typeNotify: 'success'
      })
    } catch (e) {
      setLoading(false)
      return ToastNotification({
        text: 'Falha ao atualizar seus dados, tente novamente mais tarde ou entre em contato com o suporte.',
        typeNotify: 'error'
      })
    }
  }

  function handleToInvoices() {
    navigate('/invoices')
  }

  function handleChangeAvatar() {}

  if (!loggued) {
    return <Navigate to={'/sign'} replace state={{ from: location }} />
  }

  return (
    <>
      <Loading isLoading={loading} />
      <Helmet>
        <meta name="description" content="Dados de usuário Danki Club" />
        <title>Minha conta - Danki Club</title>
      </Helmet>
      <Background>
        <h1>Minha Conta</h1>
        <Content>
          <section className={'background-transparency'}>
            <form
              autoComplete={'off'}
              onSubmit={handleSubmit((data) => handleVerification(data))}
            >
              <h2>
                Olá {user.first_name} seja bem-vindo(a),
                <span>Confira e altere seus dados se precisar.</span>
              </h2>
              <div className="avatar">
                <h4>Foto de perfil</h4>
                <div className="picture">
                  <img src={user.url_cover} alt="" />
                </div>
                <Button
                  title={'Alterar foto'}
                  disabled={true}
                  onClick={handleChangeAvatar}
                  type={'button'}
                />

                <p className={'vigence'}>
                  Vigência do plano
                  {user.status ? (
                    <span className={'text-green uppercase'}>
                      {user.access_limit}
                    </span>
                  ) : (
                    <span className={'text-red uppercase animate-scale'}>
                      <strong>Vencido</strong>
                    </span>
                  )}
                </p>
              </div>
              <div className="row">
                <div>
                  <label htmlFor="email">E-mail</label>
                  <InputForm
                    disabled={true}
                    readOnly={true}
                    control={control}
                    name={'email'}
                    type="email"
                    id={'email'}
                    placeholder={'E-mail'}
                    value={user.email}
                    error={errors.email && errors.email.message}
                  />
                </div>
                {user.plan_beneficies && user.plan === 'Sênior' && (
                  <div>
                    <label htmlFor="walletId">Wallet Address</label>
                    <InputForm
                      name={'walletId'}
                      control={control}
                      type="text"
                      id={'walletId'}
                      placeholder={
                        user.wallet_id_partial ?? 'Insira sua Wallet ID'
                      }
                      defaultValue={''}
                      error={errors.walletId && errors.walletId.message}
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="firstName">Nome</label>
                  <InputForm
                    name={'firstName'}
                    control={control}
                    type="text"
                    id={'firstName'}
                    placeholder={'Primeiro nome'}
                    defaultValue={user.first_name}
                    error={errors.firstName && errors.firstName.message}
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Sobrenome</label>
                  <InputForm
                    name={'lastName'}
                    control={control}
                    type="text"
                    id={'lastName'}
                    placeholder={'Sobrenome'}
                    defaultValue={user.last_name}
                    error={errors.lastName && errors.lastName.message}
                  />
                </div>
                <div>
                  <label htmlFor="password">Senha</label>
                  <InputForm
                    name={'password'}
                    control={control}
                    type="password"
                    autoComplete={'off'}
                    id={'password'}
                    placeholder={'Senha'}
                    securityEntry={true}
                    error={errors.password && errors.password.message}
                  />
                </div>
                <div>
                  <label htmlFor="passwordConfirm">Confirme a Senha</label>
                  <InputForm
                    name={'passwordConfirm'}
                    control={control}
                    type="password"
                    autoComplete={'off'}
                    id={'passwordConfirm'}
                    placeholder={'Confirme a Senha'}
                    securityEntry={true}
                    error={
                      errors.passwordConfirm && errors.passwordConfirm.message
                    }
                  />
                </div>
                <div>
                  <Button title={'Salvar'} disabled={loading} type={'submit'} />
                </div>
              </div>
            </form>

            <div className="invoices">
              <Button
                title={'Acessar Faturas'}
                color={'var(--gold-300)'}
                onClick={handleToInvoices}
              />
            </div>
          </section>
        </Content>
      </Background>
    </>
  )
}
