import styled from 'styled-components'

export const Content = styled.div`
  height: 100%;
  margin-top: 20px;
  padding-bottom: 60px;

  .background-transparency {
    height: 100%;
    width: 100%;
    background-color: var(--purple-200-rgba);
    padding: 20px;

    display: grid;
    grid-template-columns: 300px 1fr;
    grid-column-gap: 1rem;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      place-items: center;
      place-content: flex-start;
      grid-row-gap: 1rem;
    }

    .plan {
      width: 100%;
      display: grid;
      place-items: center;
      place-content: center;
      place-self: start;
      grid-row-gap: 1rem;

      border: 4px solid var(--purple-300-rgba);
      background-color: var(--purple-200);
      border-radius: 4px;
      padding: 30px 10px;

      .focus {
        width: 100%;
        padding: 20px;
        background-color: var(--black-rgba);
        text-align: center;
      }

      p {
        text-align: center;

        span {
          display: block;
          width: 100%;
          margin-top: 5px;
        }
      }

      > div {
        width: 100%;

        h3 {
          text-transform: uppercase;
          font-size: 1rem;
          color: var(--green-300);
          margin-bottom: 10px;
        }

        > p {
          width: 100%;
          text-align: left;
          display: grid;
          grid-template-columns: 30px 1fr;
          align-items: center;

          svg {
            margin-top: 5px;
            margin-right: 5px;
          }
          
          span {
            
          }
        }
      }

      .picture {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        overflow: hidden;
        box-shadow: 0 0 2rem var(--black-rgba);

        img {
          width: 180px;
          height: auto;
        }
      }
    }

    .invoices {
      position: relative;
      width: 100%;

      > div {
        width: 100%;

        &:first-child {
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid var(--purple);
        }

        a {
          font-weight: 900;
          color: var(--pink);
          text-transform: uppercase;
          font-size: 0.9rem;
          text-decoration: underline;
        }

        h2 {
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1.1rem;
          line-height: 26px;

          small {
            color: var(--white-400);
          }
        }
      }

      .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: var(--purple-200-rgba);
      }

      .table-hover {
        tbody tr {
          cursor: default;
          transition: all 0.4s;

          &:hover {
            transition: all 0.4s;
            background-color: var(--pink);
            transform: scale(1.01);
          }
        }
      }

      .table-responsive {
        ::-webkit-scrollbar {
          height: 5px;
        }

        ::-webkit-scrollbar-track {
          background-color: var(--purple-200);
        }

        ::-webkit-scrollbar-thumb {
          background-color: var(--purple);
          border-radius: 5px;

          :hover {
            background-color: var(--pink);
          }
        }

        @media (max-width: 768px) {
          table > tbody > tr > td,
          tr > th {
            white-space: nowrap;
          }

          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: -ms-autohiding-scrollbar;
        }
      }

      .table {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        max-width: 100%;
        background-color: var(--black-rgba);

        border-collapse: collapse;
        display: table;

        th,
        td {
          vertical-align: top;
        }

        thead,
        th {
          vertical-align: bottom;
        }

        > thead > tr > th {
          border-bottom-width: 1px;
          font-size: 1rem;
          font-weight: 400;
        }

        > thead > tr > th,
        > tbody > tr > th,
        > tfoot > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tfoot > tr > td {
          padding: 12px 8px;
          vertical-align: middle;
          border-color: var(--white-400);
        }

        thead {
          tr {
            th {
              font-size: 1.063rem;
            }
          }
        }

        > tbody > tr {
          position: relative;
        }
      }
    }
  }
`
