import React, { FormEvent, useEffect, useState } from 'react'
import { Content, ModalContent, VideoCrop } from './styles'
import { Background } from '../../components/Background'
import { Navigate, useParams } from 'react-router-dom'
import { api } from '../../services/api'
import { useAgent } from '../../hooks/agent'
import { Helmet } from 'react-helmet'
import { Loading } from '../../components/Loading'
import { IVideoDataProps } from '../../Interfaces/VideoProps'
import { Button } from '../../components/Form/Button'
import {
  ToastMultipleNotification,
  ToastNotification
} from '../../services/toast'
import { Comment } from '../../components/Comment'
import { useAuth } from '../../hooks/auth'
import axios from 'axios'
import { ModalDefault } from '../../components/ModalDefault'
import Vimeo from '@u-wave/react-vimeo'
import { FavoriteAction } from '../../components/FavoriteAction'

const { REACT_APP_STORAGE_TOKEN } = process.env

export function PlayerVideo() {
  const [video, setVideo] = useState({} as IVideoDataProps)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [send, setSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sendMessage, setSendMessage] = useState<string | null>(null)
  const [comment, setComment] = useState('')

  const { user, signOut } = useAuth()
  const { agentData } = useAgent()
  const params = useParams()
  const { video_id } = params

  async function handleRegisterEndView() {
    try {
      await api.patch(
        `/videos/register-view-end/${video.id}`,
        {
          mark_and_view_at: true
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      return ToastNotification({
        text: 'Marcamos o vídeo como assistido!',
        typeNotify: 'success'
      })
    } catch (e) {
      return ToastNotification({
        text: 'Não foi possível executar está ação, tente novamente mais tarde.',
        typeNotify: 'warning'
      })
    }
  }

  async function handleRegisterEndViewWithDate() {
    try {
      await api.patch(
        `/videos/register-view-end/${video.id}`,
        {
          mark_and_view_at: false
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      return ToastNotification({
        text: 'Obrigado por assistir!',
        typeNotify: 'info'
      })
    } catch (e) {
      // return ToastNotification({
      //   text: 'Não foi possível executar está ação, tente novamente mais tarde.',
      //   typeNotify: 'warning'
      // })
    }
  }

  async function handleOnSubmit(event: FormEvent) {
    event.preventDefault()
    if (comment.length < 10) {
      return ToastNotification({
        text: 'Digite pelo menos 10 caractéres em seu comentário',
        typeNotify: 'info'
      })
    }
    try {
      setLoading(true)
      setSendMessage('Enviando...')
      const { data } = await api.post(
        `/comments/video/create/${video_id}`,
        {
          content: comment
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setComment('')
      setSendMessage(null)
      setLoading(false)
      return ToastNotification({
        text: data.message,
        typeNotify: 'success'
      })
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
      }
      return ToastNotification({
        text: 'Falha ao enviar o comentário, tente novamente mais tarde ou entre em contato com o suporte.',
        typeNotify: 'error'
      })
    }
  }

  function handleOpenModal() {
    setIsOpenModal(true)
  }

  function handleCloseModal() {
    setIsOpenModal(false)
  }

  async function handleModalSubmit(form: FormEvent) {
    form.preventDefault()
    if (title.length < 3) {
      return ToastNotification({
        text: 'Digite pelo menos 3 caractéres para o título',
        typeNotify: 'warning'
      })
    }
    if (description.length < 50) {
      return ToastNotification({
        text: 'Algo me diz que você não está elaborando corretamente o seu pedido, tente colocar mais alguns detalhes.',
        typeNotify: 'warning'
      })
    }
    try {
      await api.post(
        `sugestion/create`,
        {
          title,
          description
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              String(REACT_APP_STORAGE_TOKEN)
            )}`
          }
        }
      )
      setTitle('')
      setDescription('')
      setIsOpenModal(false)
      setLoading(false)
      return ToastNotification({
        text: 'Agradecemos muito pela sua sugestão de vídeo! Vamos analisar e te daremos um retorno assim que possível!',
        typeNotify: 'success'
      })
    } catch (e) {
      setLoading(false)
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.data) {
          const { data } = e.response
          if (data.errors) {
            return ToastMultipleNotification(data.errors)
          } else {
            if (data.message) {
              return ToastNotification({
                text: data.message,
                typeNotify: 'warning'
              })
            }
          }
        }
      }
      return ToastNotification({
        text: 'Não foi possível enviar sua sugestão, tente novamente mais tarde.',
        typeNotify: 'error'
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!video.id) {
        try {
          const { data } = await api.get(`/videos/view/${video_id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                String(REACT_APP_STORAGE_TOKEN)
              )}`
            }
          })
          setVideo(data)
        } catch (e) {
          if (axios.isAxiosError(e)) {
            // @ts-ignore
            if (e.response.status === 401) {
              signOut().then()
            }
          }
        }
      }
      const {
        userAgent,
        IPv4,
        city,
        state,
        country_name,
        latitude,
        longitude
      } = agentData
      if (userAgent && !send) {
        setSend(true)
        await api.post(
          `/videos/register-view/${video_id}`,
          {
            city,
            state,
            latitude,
            longitude,
            ip: IPv4,
            user_agent: userAgent,
            country: country_name
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                String(REACT_APP_STORAGE_TOKEN)
              )}`
            }
          }
        )
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video, video_id, send, agentData])

  if (!user.status) {
    return <Navigate to={'/invoices'} replace />
  }

  if (!video.id) {
    return <Loading />
  }

  return (
    <>
      <Loading isLoading={loading} />
      <Helmet>
        <meta name="description" content="Danki Club" />
        <title>Dashboard Danki Club</title>
      </Helmet>
      <Background>
        <Content>
          <h1>{video.title}</h1>
          <VideoCrop cover={video.cover_url}>
            {video.external_id ? (
              <Vimeo
                pip
                responsive
                video={video.external_id}
                autoplay
                speed
                color={'d83964'}
                onEnd={handleRegisterEndViewWithDate}
              />
            ) : (
              <video poster={video.cover_url} />
            )}
          </VideoCrop>
          <div className={'settings'}>
            <div className={'author'}>
              <div className="profile">
                <img src={video.cover_author} alt={video.author} />
              </div>
              <p>
                Vídeo por <span>{video.author}</span>
              </p>
            </div>
            <div className="buttons-container">
              <FavoriteAction video={video.id} isFavorite={video.is_favorite} />
              {/*Favoritar*/}
              {/*Like e deslike*/}
              {user.plan_beneficies && (
                <Button
                  title={'Encomendar video'}
                  type={'button'}
                  color={'var(--gold-200)'}
                  disabled={loading}
                  onClick={handleOpenModal}
                />
              )}
              {video.external_id && (
                <Button
                  title={'Marcar como assistido'}
                  type={'button'}
                  disabled={loading}
                  onClick={handleRegisterEndView}
                />
              )}
            </div>
          </div>
          <section className={'data-container'}>
            <article>
              <header>
                <h1>Descrição</h1>
              </header>
              {video.external_id ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: video.content ? video.content : video.description
                  }}
                />
              ) : (
                <>
                  <h2 className={'attention animate-scale'}>
                    Este vídeo será lançado em breve
                  </h2>
                  {video.laucher_at && (
                    <p className={'prevision'}>
                      Data prevista: {video.laucher_at}
                    </p>
                  )}
                </>
              )}
            </article>

            <article>
              <header>
                <h1>Links e arquivos</h1>
              </header>
              {video.external_id ? (
                <div>O Vídeo não possui anexos</div>
              ) : (
                <>
                  <h2 className={'attention animate-scale'}>
                    Este vídeo será lançado em breve
                  </h2>
                  {video.laucher_at && (
                    <p className={'prevision'}>
                      Data prevista: {video.laucher_at}
                    </p>
                  )}
                </>
              )}
            </article>
          </section>
          <section className={'messages'}>
            <h2>Comentários</h2>
            <Comment video={video.id} />

            <div className="form-comment">
              <div className="picture">
                <img src={user.url_cover} alt="Nome" />
              </div>
              <form onSubmit={handleOnSubmit} method={'post'}>
                <label htmlFor="comment">Escreva algo para comentar</label>
                <textarea
                  name="comment"
                  id="comment"
                  rows={4}
                  placeholder={'Comente...'}
                  value={comment}
                  disabled={loading}
                  onChange={(event) => setComment(event.target.value)}
                />
                <div>
                  <Button
                    title={sendMessage ?? 'Enviar comentário'}
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          </section>
        </Content>
      </Background>
      {user.plan_beneficies && (
        <ModalDefault isOpen={isOpenModal} closeModal={handleCloseModal}>
          <ModalContent onSubmit={handleModalSubmit}>
            <h1>{`Olá ${user.first_name}, encomende seu vídeo`}</h1>
            <p>
              Para encomendar seu vídeo, preencha com detalhes os campos abaixo.
              Isso irá ajudar nossa equipe a elaborar um conteúdo bem fiel a sua
              solicitação.
            </p>

            <div className="form-control">
              <label htmlFor="title">Título do vídeo</label>
              <input
                type={'text'}
                name={'title'}
                placeholder={'Título sugerido para o vídeo'}
                onChange={(input) => setTitle(input.target.value)}
              />
            </div>
            <div className="form-control">
              <p>
                Não esqueça de dar detalhes sobre o tipo de vídeo que você quer
                ver aqui no Danki Club, vale lembrar que o vídeo sugerido já
                pode estar sendo desenvolvido.
              </p>
              <p>
                Também pode acontecer de seu vídeo ainda não possuir um
                especialista da área para trazer um conteúdo rico em
                conhecimento, mas não se preocupe, vamos guardar sua sugestão
                com carinho e quando possível atendê-la.
              </p>
              <label htmlFor="description">Descrição detalhada do vídeo</label>
              <textarea
                name={'description'}
                rows={6}
                placeholder={'Detalhes do vídeo'}
                onChange={(textarea) => setDescription(textarea.target.value)}
              />
            </div>

            <div>
              <Button title={'Enviar solicitação de vídeo'} type={'submit'} />
            </div>
          </ModalContent>
        </ModalDefault>
      )}
    </>
  )
}
