import React from 'react'
import { Container } from './styles'
import LoadingSvg from '../../assets/images/loading.svg'

interface IProps {
  isLoading?: boolean
}

export function Loading({ isLoading = true }: IProps) {
  return (
    <Container enabled={isLoading}>
      <img src={LoadingSvg} alt="Carregando..." />
    </Container>
  )
}
