import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../hooks/auth";
import React, {ReactNode, useEffect, useState} from "react";
import {Loading} from "../components/Loading";

interface ProtectedRouteProps {
  children: ReactNode
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const location = useLocation()
  const { loggued, accountVerified, refreshLoading } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (refreshLoading) {
      setLoading(false)
    }
  }, [refreshLoading])

  if (!loggued) {
    return <Navigate to={'/sign'} replace state={{ from: location }} />
  }

  if (loading) {
    return <Loading />
  }

  if (loggued && !accountVerified) {
    return (
        <Navigate
            to={'/email/verification-notification'}
            replace
            state={{ from: location }}
        />
    )
  }

  return <>{children}</>
}
