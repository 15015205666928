import React from 'react';
import {Content} from './styles';

import {Background} from "../../components/Background";
import {Helmet} from "react-helmet";

export function History() {

    return (
        <>
            <Helmet>
                <meta name="description" content="Danki Club" />
                <title>Dashboard Danki Club</title>
            </Helmet>
            <Background>
                <h1>Histórico</h1>
                <Content>
                    <section>
                        Em breve
                    </section>
                </Content>
            </Background>
        </>
    );

}
