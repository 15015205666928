import React, { useState } from 'react'
import Modal from 'react-modal'
import 'react-toastify/dist/ReactToastify.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ToastContainer } from 'react-toastify'
import { Container, GlobalStyle } from './assets/styles/global'

import { Menu } from './components/Menu'
import { Footer } from './components/Footer'
import { Routes } from './routes'
import { AuthProvider } from './hooks/auth'

Modal.setAppElement('#root')

export default function App() {
  const [activeMenu, setActiveMenu] = useState(false)

  function handleActiveMenu(action = true) {
    if (!action) {
      setActiveMenu(false)
      return
    }
    setActiveMenu(!activeMenu)
  }

  return (
    <AuthProvider>
      <Container>
        <main>
          <Menu active={activeMenu} onActiveMenu={handleActiveMenu} />
          <Routes />
        </main>
        <Footer />
      </Container>
      <GlobalStyle />
      <ToastContainer />
    </AuthProvider>
  )
}
